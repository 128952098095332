
function loadJs(url) {
  return new Promise((resolve, reject) => {
      const script = document.createElement("script")
      script.src = url
      script.onload = resolve
      script.onerror = reject
      document.body.appendChild(script)
  });
}
async function asyncLoadJs(para) {
  await loadJs('https://d2v4tz4zvhrua.cloudfront.net/production/dist/2021-07-19-1626682267011/common/TemplateBuilder.min.js').catch(err => { })
}
asyncLoadJs().then(() => {
let template = new TemplateBuilder({
  cdn: 'https://d2v4tz4zvhrua.cloudfront.net/production/dist/2021-10-08-1633652259337',
  plugins: [
    'https://man.vm5apis.com/dist/anime.js/2.0.2/anime.min.js'
  ],
  adformats: [
    'BannerContainer',
  ],
  components: [
    'Flyout', 'NewCloseButton'
  ],
  develop: false,
  developRelativePath: '../../../..',
  Tracking: [
    function (self) {
      return {
        option: {
          wantedTrackingList: [
            'impression', 'close', 'custom'
          ],
          fbPixelId: '748409338986541',
          wantedFbPixelList: [
            'impression', 'close', 'custom'
          ],
        },
        demoOption: {
          'text:3rd_party_track_url': '',
        }
      }
    }
  ],
  AdBox: [
    function (self) {
      return {
        refName: 'AdBox',
        option: {
          boxStyle: 'fixed',
          plugins: [
            'https://man.vm5apis.com/dist/anime.js/2.0.2/anime.min.js'
          ]
        },
        demoOption: {
          'request_id': '55667788',
        }
      }
    },
  ],
  BaseAdUnit: [
    function (self) {
      return {
        adformatName: 'BannerContainer',
        refName: 'BannerContainer',
        option: {
          el: self.refs['AdBox'].practicalView,
          TrackingHelper: self.TrackingHelper,
          AdBox: self.refs['AdBox'],
          enableClipPath: false,
          doImpression: false,
          disableCta: true,
          'text:banner_ratio': '32:10',
          onbannerinit: [
            function (container) {
              self.refs['Flyout'].init();
            }
          ],
        },
        demoOption: {
        }
      };
    }
  ],
  OnBaseAdUnitPrepared: [
    function (self) {
      return {
        componentName: 'Flyout',
        refName: 'Flyout',
        option: {
          el: self.refs['BannerContainer'].adContainerView,
          disableAutoInit: true,
          AdBox: self.refs['AdBox'],
          onflyoutend: [
            function (component, extra) {
              self.TrackingHelper.doImpression(self.refs['AdBox'].practicalView);
              self.refs['NewCloseButton'].showButton();
            },
          ]
        },
        demoOption: {
          'image:banner': 'https://d2v4tz4zvhrua.cloudfront.net/manual_uploads/20211015/resources/ad_gallery/banner.gif',
          'image:leader': 'https://d2v4tz4zvhrua.cloudfront.net/manual_uploads/20211015/resources/ad_gallery/flyout.gif',
          'image:opening': '',
          'text:move_duration': `3500`,
          'text:opening_fadeout_delay': ``,
          'text:banner_showup_delay': ``,

          'text:horizontal_density_level': `7`,
          'text:vertical_density_level': ``,

          'text:fly_over_banner': ``,
          'text:mass_vertical_elements': 'false',
          'text:mass_horizontal_elements': 'false',

          'text:banner_top_offset': self.refs['BannerContainer'].config['text:banner_top_offset'],
          'text:banner_bottom_offset': self.refs['BannerContainer'].config['text:banner_bottom_offset'],

          'text:shake_elements': `false`,
          'text:shake_level': `1`,

          'text:banner_bottom_offset': ``,
          'cta:default': 'https://adison.com.tw/landing?utm_source=adison_ad_cta',
        }
      }
    },
    function (self) {
      return {
        componentName: 'NewCloseButton',
        refName: 'NewCloseButton',
        option: {
          el: self.refs['AdBox'].boxWrapper,
          closeTarget: self.refs['AdBox'].boxWrapper,
          disableCta: self.refs['BannerContainer'].config.disableCta,
          enableExpand: self.refs['BannerContainer'].config.enableExpand,
          autoShowButton: false,
          TrackingHelper: self.TrackingHelper,
        },
        demoOption: {
          'request_id': '55667788',
          'text:show_close_mode': 'fadein',
          'text:close_style': 'general',
          'text:vertical_align_button_percentage': '0',
          'text:horizontal_align_button_percentage': '100',
          'text:close_out_container': 'true',
          'cta:default': 'https://adison.com.tw/landing?utm_source=adison_ad_cta',
        }
      }
    }
  ]
});
})