function loadJs(url) {
    return new Promise((resolve, reject) => {
        const script = document.createElement("script")
        script.src = url
        script.onload = resolve
        script.onerror = reject
        document.body.appendChild(script)
    });
}
async function asyncLoadJs(para) {
    await loadJs('https://cdnjs.cloudflare.com/ajax/libs/jquery/2.1.3/jquery.min.js').catch(err => { })
    await loadJs('https://man.vm5apis.com/dist/hammer.js/2.0.8/hammer.min.js').catch(err => { })
    await loadJs('https://man.vm5apis.com/dist/jquery.hammerjs/2.0.0/jquery.hammer.js').catch(err => { })
    await loadJs('https://cdn.jsdelivr.net/velocity/1.0.0/velocity.min.js').catch(err => { })
    await loadJs('https://man.vm5apis.com/dist/raf.js').catch(err => { })
    await loadJs('https://man.vm5apis.com/dist/anime.js/3.0.1/anime.min.js').catch(err => { })
    await loadJs('https://d2v4tz4zvhrua.cloudfront.net/production/dist/2020-02-24-1582531073384/common/TemplateBuilder.min.js').catch(err => { })
}
asyncLoadJs().then(() => {
    let template = new TemplateBuilder({
        cdn: 'https://d2v4tz4zvhrua.cloudfront.net/production/dist/2020-02-24-1582531073384',
        plugins: [
            // 'https://man.vm5apis.com/dist/anime.js/3.0.1/anime.min.js',
            // 'https://man.vm5apis.com/dist/jquery/2.1.3/jquery.min.js',
            // 'https://man.vm5apis.com/dist/hammer.js/2.0.8/hammer.min.js',
            // 'https://man.vm5apis.com/dist/jquery.hammerjs/2.0.0/jquery.hammer.js',
            // 'https://man.vm5apis.com/dist/raf.js'
        ],
        adformats: [
            'BannerContainer', 'UprightAround'
        ],
        components: [
            'BannerThreeD', 'CloseButton', 'Leading', 'ExpandHelper'
        ],
        develop: false,
        Tracking: [
            function (self) {
                return {
                    option: {
                        el: '#vmfive-ad-unit-container-55667788',
                        'request_id': '[request_id]',
                        wantedTrackingList: [
                            'impression', 'close', 'video_percentage', 'video_progress', 'custom', 'view'
                        ],
                        fbPixelId: '',
                        wantedFbPixelList: [],
                    },
                    demoOption: {
                        'text:3rd_party_track_url': '',
                    }
                }
            }
        ],
        BaseAdUnit: [
            function (self) {
                return {
                    adformatName: 'BannerContainer',
                    refName: 'BannerContainer',
                    option: {
                        el: '#vmfive-ad-unit-container-55667788',
                        'request_id': '[request_id]',
                        'text:banner_ratio': '32:15',
                        enableClipPath: false,
                        'text:logo_horizontal_alignment': 'left',
                        disableCta: true,
                        TrackingHelper: self.TrackingHelper,
                    },
                    demoOption: {
                        'cta:default': 'https://adison.com.tw/landing/',
                    }
                };
            },
            function (self) {
                return {
                    adformatName: 'UprightAround',
                    refName: 'UprightAround',
                    option: {
                        el: '#vmfive-ad-unit-container-[request_id]-expand',
                        'request_id': '[request_id]-expand',
                        videoScale: '16:9',
                        medias:
                            '[' +
                            '{"image": "https://ad-demo.vmfive.com/demos/showcase/20200224_adidas_climacool/resources/adidas_climacool_lightbox01_bg.gif", "mp4": "https://ad-demo.vmfive.com/demos/showcase/20200224_adidas_climacool/resources/adidasclimacool_master.mp4", "coverImage": "https://ad-demo.vmfive.com/demos/showcase/20200224_adidas_climacool/resources/adidas_coverimage.png"},' +
                            '{"image": "https://ad-demo.vmfive.com/demos/showcase/20200224_adidas_climacool/resources/adidas_climacool_lightbox02.gif"},' +
                            '{"image": "https://ad-demo.vmfive.com/demos/showcase/20200224_adidas_climacool/resources/adidas_climacool_lightbox03.gif"}' +
                            ']',
                    },
                    demoOption: {
                        'cta:default': `https://adison.com.tw/?utm_source=adison_ad_cta`,
                        'image:background': 'https://ad-demo.vmfive.com/demos/showcase/20200224_adidas_climacool/resources/adidas_climacool_lightbox02.gif',
                        'text:3rd_party_track_url': '',
                        'dfp_url': ``,
                        'text:banner_around_interval': '3000',
                    }
                };
            }
        ],
        OnBaseAdUnitPrepared: [
            function (self) {
                return {
                    componentName: 'BannerThreeD',
                    refName: 'BannerThreeD',
                    option: {
                        el: self.refs['BannerContainer'].adContainerView,
                        'request_id': self.refs['BannerContainer'].config.request_id,
                    },
                    demoOption: {
                        'downloaded_pixel': 'https://vaser-track.vm5apis.com/v2/track?requestId=&event=downloaded&placementId=&campaignId=&audienceGroupId=&creativeId=&timezone=8&creativeFormat=richmedia&pixel=1&x=0',
                        'text:cta_in_new_window': '',
                        'dfp_url': '',
                        'cta_prefix_url': 'https://vaser-track.vm5apis.com/v2/track?requestId=&placementId=&campaignId=&audienceGroupId=&creativeId=&timezone=8&creativeFormat=richmedia&pixel=1&x=0&event=click',
                        'cta:default': 'https://adison.com.tw/landing/',
                        'image:background': '',
                        'text:banner_threed_rotate_initial_delay': '0',
                        bannerRatio: '32:10',
                        disableAutoStartRotate: true,
                        disableCta: true,

                        cubic: {
                            front: {
                                imageURL: 'https://ad-demo.vmfive.com/demos/showcase/20200224_adidas_climacool/resources/adidas_climacool_spincubic_01.png',
                                ctaURL: 'https://adison.com.tw/landing/',
                            },
                            bottom: {
                                imageURL: 'https://ad-demo.vmfive.com/demos/showcase/20200224_adidas_climacool/resources/adidas_climacool_spincubic_02.png',
                                ctaURL: 'https://adison.com.tw/landing/',
                            },
                            back: {
                                imageURL: 'https://ad-demo.vmfive.com/demos/showcase/20200224_adidas_climacool/resources/adidas_climacool_spincubic_03.png',
                                ctaURL: 'https://adison.com.tw/landing/',
                            },
                            top: {
                                imageURL: 'https://ad-demo.vmfive.com/demos/showcase/20200224_adidas_climacool/resources/adidas_climacool_spincubic_04.png',
                                ctaURL: 'https://adison.com.tw/landing/',
                            },
                            left: {
                                imageURL: '',
                                ctaURL: '',
                            },
                            right: {
                                imageURL: '',
                                ctaURL: '',
                            }
                        },
                        heightRatio: '1',
                        bannerRatio: self.refs['BannerContainer'].config.bannerRatio,
                    }
                }
            },
            function (self) {
            },
            function (self) {
                return {
                    componentName: 'CloseButton',
                    refName: 'CloseButton',
                    option: {
                        el: self.refs['BannerContainer'].adContainerView,
                        'request_id': '[request_id]',
                        'track_url': '[track_url]',
                        'cta:default': `https://www.adidas.com.tw/product/35457852?utm_source=VM5&utm_medium=Panorama&utm_campaign=VRCT&utm_content=0905TGOP`,
                        'text:close_button_horizontal_alignment': 'right',
                        'text:beautify_percent': '80',
                        TrackingHelper: self.TrackingHelper,
                    }
                }
            },
            function (self) {
                return {
                    componentName: 'CloseButton',
                    refName: 'CloseButtonIn',
                    option: {
                        el: self.refs['UprightAround'].overlayView,
                        'request_id': '[request_id]',
                        'track_url': '[track_url]',
                        'cta:default': `https://www.adidas.com.tw/product/35457852?utm_source=VM5&utm_medium=Panorama&utm_campaign=VRCT&utm_content=0905TGOP`,
                        'text:show_close_mode': 'fadein',
                        'text:close_button_horizontal_alignment': 'right',
                        'text:beautify_percent': '80',
                        TrackingHelper: self.TrackingHelper,
                        onExpandHelperClose: [
                            function (comp) {
                                self.refs['ExpandHelper'].expandedVisible('hide');
                                self.refs['ExpandHelper'].originalVisible('show');
                            }
                        ],
                    }
                }
            },
            function (self) {
                return {
                    componentName: 'ExpandHelper',
                    refName: 'ExpandHelper',
                    option: {
                        original: self.refs['BannerContainer'],
                        expanded: self.refs['UprightAround'],
                        showExpandButton: true,
                        onprepared: [
                            function (comp) {
                                comp.expandedVisible('hide');
                                comp.originalVisible('show');
                            }
                        ],
                        TrackingHelper: self.TrackingHelper,
                    }
                }
            },
            function (self) {
                self.refs['BannerContainer'].config.onctaclicked = [
                    function () {
                        self.refs['ExpandHelper'].expandedVisible('show');
                        self.refs['ExpandHelper'].originalVisible('hide');
                        self.refs['UprightAround'].makeATurnAndStart();
                    }
                ];
                self.refs['ExpandHelper'].config.onctaclicked = [
                    function () {
                        self.refs['UprightAround'].makeATurnAndStart();
                    }
                ];
            },
            function (self) {
                return {
                    componentName: 'Leading',
                    refName: 'Leading',
                    option: {
                        'request_id': '[request_id]',
                        'image:sprite_sheet': 'https://ad-demo.vmfive.com/demos/showcase/20200224_adidas_climacool/resources/adidas_climacool_46c_2.5s.png',
                        'text:total_frame': '46',
                        'text:total_animation_during': '2.5',
                        'text:anime_delay_offset': '100',
                        spriteSheetWidth: 480,
                        spriteSheetHeight: 854,
                        containerOverlayRoot: self.refs['BannerContainer'].overlayView,
                        containerInsertRoot: self.refs['BannerContainer'].rootView,
                        oncomplete: [
                            function (com) {
                                self.refs['BannerThreeD'].startRotate();
                            }
                        ],
                    }
                }
            }
        ]
    });
})