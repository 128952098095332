function loadJs(url) {
    return new Promise((resolve, reject) => {
        const script = document.createElement("script")
        script.src = url
        script.onload = resolve
        script.onerror = reject
        document.body.appendChild(script)
    });
}
async function asyncLoadJs(para) {
    await loadJs('https://cdnjs.cloudflare.com/ajax/libs/jquery/2.1.3/jquery.min.js').catch(err => { })
    await loadJs('https://man.vm5apis.com/dist/hammer.js/2.0.8/hammer.min.js').catch(err => { })
    await loadJs('https://man.vm5apis.com/dist/jquery.hammerjs/2.0.0/jquery.hammer.js').catch(err => { })
    await loadJs('https://d2v4tz4zvhrua.cloudfront.net/production/dist/2020-04-20-1587354435084/common/TemplateBuilder.min.js').catch(err => { })
}
asyncLoadJs().then(() => {
    let template = new TemplateBuilder({
        cdn: 'https://d2v4tz4zvhrua.cloudfront.net/production/dist/2020-08-03-1596450362554',
        plugins: [
        ],
        adformats: [
            'BannerContainerBackground', 'Question'
        ],
        components: [
            'CloseButton', 'ExpandHelper'
        ],
        develop: false,
        Tracking: [
            function (self) {
                return {
                    option: {
                        el: '#vmfive-ad-unit-container-55667788',
                        'request_id': '[request_id]',
                        wantedTrackingList: [
                            'impression', 'close', 'video_percentage', 'video_progress', 'view', { 'expand': 1 }, { 'collapse': 1 }
                        ],
                        fbPixelId: '',
                        wantedFbPixelList: [],
                    },
                    demoOption: {
                        'text:3rd_party_track_url': '',
                    }
                }
            }
        ],
        // {video_percentage:'1'}
        BaseAdUnit: [
            function (self) {
                return {
                    adformatName: 'BannerContainerBackground',
                    refName: 'BannerContainerBackground',
                    option: {
                        el: '#vmfive-ad-unit-container-55667788',
                        enableClipPath: false,
                        disableCta: true,
                        enableSwipeUp: true,
                        TrackingHelper: self.TrackingHelper,
                    },
                    demoOption: {
                        'cta:default': 'https://adison.com.tw/landing/?utm_source=adison_ad_cta',
                        'image:container_background': 'https://ad-demo.vmfive.com/demos/showcase/2020_Q3adformats/business/resource/qa_banner.png',
                    }
                };
            },
        ],
        OnBaseAdUnitPrepared: [
            function (self) {
                return {
                    componentName: 'Question',
                    refName: 'Question',
                    option: {
                        el: '#vmfive-ad-unit-container-55667788-expand',
                        'request_id': '55667788-expand',
                        'text:banner_ratio': '',
                        disableCta: false,
                        TrackingHelper: self.TrackingHelper,
                    },
                    demoOption: {
                        'cta:default': 'https://adison.com.tw/landing/',
                        'text:questionText': '你有運動習慣嗎</br>你一週運動幾次？',
                        'text:answerText_1': '1~2次',
                        'text:answerText_2': '3~4次',
                        'text:answerText_3': '5次以上',
                        'text:answerTextBack_1': '你需要多多運動！',
                        'text:answerTextBack_2': '可以再多一點運動！',
                        'text:answerTextBack_3': '很健康喔！',
                        'cta:answerCta_1': 'https://adison.com.tw/landing/',
                        'cta:answerCta_2': 'https://adison.com.tw/landing/',
                        'cta:answerCta_3': 'https://adison.com.tw/landing/',
                    }
                }
            },
            function (self) {
                return {
                    componentName: 'CloseButton',
                    refName: 'CloseButton',
                    option: {
                        el: self.refs['BannerContainerBackground'].adContainerView,
                        'request_id': '[request_id]',

                        TrackingHelper: self.TrackingHelper,
                    },
                    demoOption: {
                        'text:show_close_mode': 'fadein',
                        'text:close_button_horizontal_alignment': 'right',
                        'text:beautify_percent': '80',
                    }
                }
            },
            function (self) {
                return {
                    componentName: 'ExpandHelper',
                    refName: 'ExpandHelper',
                    option: {
                        original: self.refs['BannerContainerBackground'],
                        expanded: self.refs['Question'],
                        showExpandButton: false,
                        justShowNoHide: true,
                        TrackingHelper: self.TrackingHelper,
                        onprepared: [
                            function (comp) {
                                comp.expandedVisible('hide');
                                comp.originalVisible('show');
                            }
                        ]
                    },
                    demoOption: {
                        'text:expand_button_horizontal_alignment': '',
                    }
                }
            },
            function (self) {
                self.refs['BannerContainerBackground'].config.onctaclicked = [
                    function () {
                        self.refs['ExpandHelper'].expandedVisible('show');
                    }
                ];
                self.refs['ExpandHelper'].config.onctaclicked = [
                    function () {
                        self.refs['ExpandHelper'].expandedVisible('show');
                    }
                ];
                self.refs['Question'].config.onclosed = [
                    function () {
                        self.refs['ExpandHelper'].expandedVisible('hide');
                        self.refs['ExpandHelper'].originalVisible('show');
                    }
                ];
            }
        ]
    });
})