function loadJs(url){
    return new Promise( (resolve, reject) => {
      const script = document.createElement("script")
      script.src = url
      script.onload = resolve
      script.onerror = reject
      document.body.appendChild(script)
    });
}
async function asyncLoadJs (para) {
    // 可以改成 foreach方式
    await loadJs('https://cdnjs.cloudflare.com/ajax/libs/jquery/2.1.3/jquery.min.js').catch(err => {})
    await loadJs('https://man.vm5apis.com/dist/hammer.js/2.0.8/hammer.min.js').catch(err => {})
    await loadJs('https://man.vm5apis.com/dist/jquery.hammerjs/2.0.0/jquery.hammer.js').catch(err => {})
    await loadJs('https://man.vm5apis.com/dist/raf.js').catch(err => {})
    await loadJs('https://man.vm5apis.com/dist/anime.js/3.0.1/anime.min.js').catch(err => {})
    await loadJs('https://d2v4tz4zvhrua.cloudfront.net/production/dist/2020-04-20-1587354435084/common/TemplateBuilder.min.js').catch(err => {})
}
asyncLoadJs().then(()=>{
    let template = new TemplateBuilder({
        cdn: 'https://d2v4tz4zvhrua.cloudfront.net/production/dist/2020-04-20-1587354435084',
        plugins: [
        ],
        adformats: [
            'BannerContainer',
        ],
        components: [
            'Spinning', 'CloseButton'
        ],
        develop: false,
        Tracking: [
            function (self) {
                return {
                    option: {
                        el: '#vmfive-ad-unit-container-55667788',
                        'request_id': '[request_id]',
                        wantedTrackingList: [
                            'impression', 'close', 'video_percentage', 'video_progress', 'custom', 'view', { expand: '1' }, { collapse: '1' }, { accept_invitation: '1' }
                        ],
                        fbPixelId: '',
                        wantedFbPixelList: [],
                    },
                    demoOption: {
                        'text:3rd_party_track_url': '',
                    }
                }
            }
        ],
        // {video_percentage:'1'}
        BaseAdUnit: [
            function (self) {
                return {
                    adformatName: 'BannerContainer',
                    refName: 'BannerContainer',
                    option: {
                        el: '#vmfive-ad-unit-container-55667788',
                        enableClipPath: false,
                        disableCta: true,
                        TrackingHelper: self.TrackingHelper,
                    },
                    demoOption: {
                        'cta:default': 'https://adison.com.tw/landing/?utm_source=adison_ad_cta',
                        'text:banner_ratio': '32:15',
                    }
                };
            },
        ],
        OnBaseAdUnitPrepared: [
            // demos/showcase/2020_Q2/gsk/resources/gsk_banner.png
            function (self) {
                return {
                    componentName: 'Spinning',
                    refName: 'Spinning',
                    option: {
                        el: self.refs['BannerContainer'].adContainerView,
                        'request_id': '[request_id]',
                        TrackingHelper: self.TrackingHelper,
                    },
                    demoOption: {
                        'text:banner_ratio': '32:15',
                        'text:expand_close_button_horizontal_alignment': 'right',
                        'cta:default': 'https://adison.com.tw/landing/?utm_source=adison_ad_cta',
                        'text:spinning_media_click_expand': 'true',
                        hintImage: 'https://ad-demo.vmfive.com/demos/showcase/2020_Q2/kinder/resources/spinning_hint.png',
                        medias:
                            '[' +
                            '{"image": "https://ad-demo.vmfive.com/demos/showcase/20200408_14yrs/resources/14yrs_banner_01.png"},' +
                            '{"image": "https://ad-demo.vmfive.com/demos/showcase/20200408_14yrs/resources/14yrs_banner_02.png"},' +
                            '{"image": "https://ad-demo.vmfive.com/demos/showcase/20200408_14yrs/resources/14yrs_banner_03.png"},' +
                            '{"image": "https://ad-demo.vmfive.com/demos/showcase/20200408_14yrs/resources/14yrs_banner_04.png"},' +
                            '{}]',
                        expand:
                            '[' +
                            '{"image": "https://ad-demo.vmfive.com/demos/showcase/20200408_14yrs/resources/14yrs_expand_01.gif"},' +
                            '{"mp4": "https://ad-demo.vmfive.com/demos/showcase/20200408_14yrs/resources/qiy_video_480p.mp4", "image": "https://ad-demo.vmfive.com/demos/showcase/20200408_14yrs/resources/14yrs_expand_02.gif"},' +
                            '{"image": "https://ad-demo.vmfive.com/demos/showcase/20200408_14yrs/resources/14yrs_expand_03.gif"},' +
                            '{"image": "https://ad-demo.vmfive.com/demos/showcase/20200408_14yrs/resources/14yrs_expand_04.gif"},' +
                            '{}]',
                        onhammer: [
                            function (com) {
                                self.refs['CloseButton'].closeEl.classList.remove('fadein');
                                self.refs['CloseButton'].closeBeautify.classList.remove('fadein');
                            },
                        ],
                        onExpandBanner: [
                            function (com) {
                                self.refs['CloseButton'].closeEl.classList.remove('fadein');
                                self.refs['CloseButton'].closeBeautify.classList.remove('fadein');
                            },
                        ],
                        oncloseexpanded: [
                            function (com) {
                                self.refs['CloseButton'].closeEl.classList.add('fadein');
                                self.refs['CloseButton'].closeBeautify.classList.add('fadein');
                            },
                        ],
                    }
                }
            },
            function (self) {
                return {
                    componentName: 'CloseButton',
                    refName: 'CloseButton',
                    option: {
                        el: self.refs['BannerContainer'].adContainerView,
                        'request_id': '[request_id]',
    
                        'text:cta_in_new_window': `[text:cta_in_new_window]`,
                        'dfp_url': `[dfp_url]`,
                        'cta_prefix_url': `[cta_prefix_url]`,
                        'cta:default': `[cta:default]`,
    
                        'text:embedded': '[text:embedded]',
                        TrackingHelper: self.TrackingHelper,
                    },
                    demoOption: {
                        'text:show_close_mode': 'fadein',
                        'text:close_button_horizontal_alignment': 'right',
                        'text:beautify_percent': '80',
                    }
                }
            },
        ]
    });
})