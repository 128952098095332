const state = {
    adFormats: {
        Featured: [],
        Dynamic: [
            {
                name: 'TakeMeThere(置底)',
                src: 'https://d2v4tz4zvhrua.cloudfront.net/manual_uploads/20211025/tmt.png',
                url: 'https://ad-demo.vmfive.com/creative/mobile-ads.html?&cid=646c8713ebd31f0001229c14&ct=web_slider',
                isNew: true
            },
            {
                name: 'TakeMeThere(展開)',
                src: 'https://d2v4tz4zvhrua.cloudfront.net/manual_uploads/20211025/tmt.png',
                url: 'https://ad-demo.vmfive.com/creative/mobile-ads.html?&cid=645b00fbb32c230001c6ecbe&ct=web_slider',
                isNew: true
            },
            {
                name: 'Recall(影音)',
                src: 'https://d2v4tz4zvhrua.cloudfront.net/manual_uploads/20211025/recall.png',
                url: 'https://ad-demo.vmfive.com/creative/mobile-ads.html?&cid=645b045db32c230001c6ecc1&ct=web_slider',
                isNew: true
            },
            {
                name: '倒數計時',
                src: 'https://ad-demo.vmfive.com/demos/showcase/20210809_dynamic_info/dynamic_icon/dynamic_countdown.png',
                url: 'https://ad-demo.vmfive.com/creative/mobile-ads.html?&cid=645b106ab32c230001c6ecd1&ct=web_impressive'
            },
            {
                name: '氣候資訊(PM2.5)',
                src: 'https://ad-demo.vmfive.com/demos/showcase/20210809_dynamic_info/dynamic_icon/dynamic_pm25.png',
                url: 'https://ad-demo.vmfive.com/creative/mobile-ads.html?&cid=645b2b5d5607e900014a9630&ct=web_slider'
            },
            {
                name: '氣候資訊(濕度)',
                src: 'https://ad-demo.vmfive.com/demos/showcase/20210809_dynamic_info/dynamic_icon/dynamic_weather.png',
                url: 'https://ad-demo.vmfive.com/creative/mobile-ads.html?&cid=6465a2f6e7e6cd000154ba55&ct=web_slider'
            },
            {
                name: '氣候資訊(氣溫)',
                src: 'https://ad-demo.vmfive.com/demos/showcase/20210809_dynamic_info/dynamic_icon/dynamic_weather.png',
                url: 'https://ad-demo.vmfive.com/creative/mobile-ads.html?&cid=645b3e315607e900014a963e&ct=web_slider'
            },
            {
                name: '距離資訊',
                src: 'https://ad-demo.vmfive.com/demos/showcase/20210809_dynamic_info/dynamic_icon/dynamic_lbs.png',
                url: 'https://ad-demo.vmfive.com/creative/mobile-ads.html?&cid=64671e08e7e6cd000154bb2b&ct=web_slider'
            }
        ],
        InRead: [
            {
                name: '探照燈',
                src: 'https://d2v4tz4zvhrua.cloudfront.net/gallery-icon/x-ray.png',
                url: 'https://ad-demo.vmfive.com/creative/mobile-ads.html?&cid=655b100151621000012677b4&ct=web_impressive',
                isNew: true
            },
            {
                name: '滑軌廣告',
                src: 'https://d2v4tz4zvhrua.cloudfront.net/gallery-icon/scroll-snapping_impressive.png',
                url: 'https://ad-demo.vmfive.com/creative/mobile-ads.html?&cid=63e9f0356a3ef50001e3b9f6&ct=web_impressive'
            },
            {
                name: '滑軌廣告(影音)',
                src: 'https://d2v4tz4zvhrua.cloudfront.net/gallery-icon/scroll-snapping-video_impressive.png',
                url: 'https://ad-demo.vmfive.com/creative/mobile-ads.html?&cid=63e9f4556a3ef50001e3b9fd&ct=web_impressive'
            },
            {
                name: '文中降落',
                src: 'https://ad-demo.vmfive.com/demos/showcase/2022Q3/demopage_icon_FSLanding.png',
                url: 'https://ad-demo.vmfive.com/creative/mobile-ads.html?&cid=6466f5d09388e900015662c3&ct=web_impressive'
            },
            {
                name: '文中降落(影音)',
                src: 'https://ad-demo.vmfive.com/demos/showcase/2022Q3/demopage_icon_FSLanding_video.png',
                url: 'https://ad-demo.vmfive.com/creative/mobile-ads.html?&cid=62d8f906fe3d3a000180ea66&ct=web_impressive'
            },
            {
                name: '無所不在(影音)',
                src: 'https://ad-demo.vmfive.com/demos/showcase/2021_Q3_newformat_demo/icons/demopage_icon_innout_video.png',
                url: 'https://ad-demo.vmfive.com/creative/mobile-ads.html?&cid=64649d759388e900015661b0&ct=web_impressive'
            },
            {
                name: '無所不在',
                src: 'https://ad-demo.vmfive.com/demos/showcase/2021_Q3_newformat_demo/icons/demopage_icon_innout.png',
                url: 'https://ad-demo.vmfive.com/creative/mobile-ads.html?&cid=64649e2ae7e6cd000154ba28&ct=web_impressive'
            },
            {
                name: '翻轉新視界(影音)',
                src: 'https://ad-demo.vmfive.com/demos/showcase/2021_Q3_newformat_demo/icons/demopage_icon_bump.png',
                url: 'https://ad-demo.vmfive.com/creative/mobile-ads.html?&cid=645c5efbc8d564000166c66e&ct=web_impressive'
            },
            {
                name: '翻轉新視界',
                src: 'https://ad-demo.vmfive.com/demos/showcase/2021_Q3_newformat_demo/icons/demopage_icon_bump_banner.png',
                url: 'https://ad-demo.vmfive.com/creative/mobile-ads.html?&cid=645c618eb32c230001c6ed34&ct=web_impressive'
            },
            {
                name: '迴廊廣告',
                src: 'https://ad-demo.vmfive.com/demos/showcase/2020Q4_adformats/icons/demopage_icon_cloister.png',
                url: 'https://ad-demo.vmfive.com/creative/mobile-ads.html?&cid=645c67235607e900014a96a8&ct=web_impressive'
            },
            {
                name: '動態插頁廣告',
                src: 'https://ad-demo.vmfive.com/demos/showcase/2019Q3_ad_formats/resource/icons/demopage_icon__flyer.png',
                url: 'https://ad-demo.vmfive.com/creative/mobile-ads.html?&cid=645c863fc8d564000166c693&ct=web_impressive'
            },
            {
                name: '原生廣告',
                src: 'https://ad-demo.vmfive.com/demos/showcase/2019Q3_ad_formats/resource/icons/demopage_icon__native.png',
                url: 'https://ad-demo.vmfive.com/creative/mobile-ads.html?&cid=645c90b75607e900014a96e9&ct=web_native'
            },
            {
                name: '原生翻轉',
                src: 'https://ad-demo.vmfive.com/demos/showcase/2019Q3_ad_formats/resource/icons/demopage_icon__native_flip.png',
                url: 'https://ad-demo.vmfive.com/creative/mobile-ads.html?&cid=645c944a5607e900014a96fc&ct=web_native'
            },
            {
                name: '動態文中',
                src: 'https://ad-demo.vmfive.com/demos/showcase/2019Q3_ad_formats/resource/icons/demopage_icon__native_dynamic.png',
                url: 'https://ad-demo.vmfive.com/creative/mobile-ads.html?&cid=645cb276c8d564000166c70c&ct=web_native'
            },
            {
                name: '輪播+動態',
                src: 'https://ad-demo.vmfive.com/demos/showcase/2019Q3_ad_formats/resource/icons/demopage_icon__carousel_dynamic.png',
                url: 'https://ad-demo.vmfive.com/creative/mobile-ads.html?&cid=646b38f7ebd31f0001229b4a&ct=web_native'
            },
            {
                name: '全景串接廣告',
                src: 'https://ad-demo.vmfive.com/demos/showcase/2019Q3_ad_formats/resource/icons/demopage_icon__panorama.png',
                url: 'https://ad-demo.vmfive.com/creative/mobile-ads.html?&cid=645cbc8fb32c230001c6edb9&ct=web_native'
            },
            {
                name: '滾動式蓋版',
                src: 'https://ad-demo.vmfive.com/demos/showcase/2019Q3_ad_formats/resource/icons/demopage_icon__scrollimpressive.png',
                url: 'https://ad-demo.vmfive.com/creative/mobile-ads.html?&cid=6467297ee7e6cd000154bb57&ct=web_impressive'
            },
            {
                name: '滾動式蓋版(全屏)',
                src: 'https://ad-demo.vmfive.com/demos/showcase/2019Q3_ad_formats/resource/icons/demopage_icon__scrollimpressive.png',
                url: 'https://ad-demo.vmfive.com/creative/mobile-ads.html?&cid=6467100208b1d0000182dc37&ct=web_impressive'
            },
            {
                name: '迷你播放器',
                src: 'https://ad-demo.vmfive.com/demos/showcase/2019Q3_ad_formats/resource/icons/demopage_icon__miniplayer.png',
                url: 'https://ad-demo.vmfive.com/demos/showcase/2019Q2_ad_formats/mini-player.html'
            }
        ],
        Slider: [
            {
                name: '貼文廣告',
                src: 'https://d2v4tz4zvhrua.cloudfront.net/gallery-icon/post.png',
                url: 'https://ad-demo.vmfive.com/creative/mobile-ads.html?&cid=66baff6bde9e9f00017d9c8a&ct=web_slider',
                isNew: true
            },
            {
                name: '問卷式廣告',
                src: 'https://d2v4tz4zvhrua.cloudfront.net/gallery-icon/brand-lift-survey.png',
                url: 'https://ad-demo.vmfive.com/creative/mobile-ads.html?&cid=6549e9b8a43e9a00014d226c&ct=web_slider',
                isNew: true
            },
            {
                name: '填單廣告',
                src: 'https://d2v4tz4zvhrua.cloudfront.net/gallery-icon/leads-ad.png',
                url: 'https://ad-demo.vmfive.com/creative/mobile-ads.html?&cid=63b7ca1a62bd2500010e6130&ct=web_slider',
                isNew: true
            },
            {
                name: '左右翻頁',
                src: 'https://ad-demo.vmfive.com/demos/showcase/2022Q3/demopage_icon_flipbook.png',
                url: 'https://ad-demo.vmfive.com/creative/mobile-ads.html?&cid=645df7c7ad85cf0001a5dea3&ct=web_slider'
            },
            {
                name: '左右翻頁(影音)',
                src: 'https://ad-demo.vmfive.com/demos/showcase/2022Q3/demopage_icon_flipbook_video.png',
                url: 'https://ad-demo.vmfive.com/creative/mobile-ads.html?&cid=645dfc437e235c0001910ee9&ct=web_slider'
            },
            {
                name: '互動應援 150高',
                src: 'https://ad-demo.vmfive.com/demos/showcase/2022Q2/reaction_150_static.png',
                url: 'https://ad-demo.vmfive.com/creative/mobile-ads.html?&cid=625cd5c5daa27e00019ae9f3&ct=web_slider'
            },
            {
                name: '互動應援 200高',
                src: 'https://ad-demo.vmfive.com/demos/showcase/2022Q2/reaction_200_static.png',
                url: 'https://ad-demo.vmfive.com/creative/mobile-ads.html?&cid=64619f047e235c0001910f78&ct=web_slider'
            },
            {
                name: '互動應援 150高(影音)',
                src: 'https://ad-demo.vmfive.com/demos/showcase/2022Q2/reaction_150_video.png',
                url: 'https://ad-demo.vmfive.com/creative/mobile-ads.html?&cid=6461a4125ee9e70001345790&ct=web_slider'
            },
            {
                name: '互動應援 200高(影音)',
                src: 'https://ad-demo.vmfive.com/demos/showcase/2022Q2/reaction_200_video.png',
                url: 'https://ad-demo.vmfive.com/creative/mobile-ads.html?&cid=6461a5e5c86016000199a3e8&ct=web_slider'
            },
            {
                name: '動態投票 150高',
                src: 'https://ad-demo.vmfive.com/demos/showcase/2022Q2/poll_150_static.png',
                url: 'https://ad-demo.vmfive.com/creative/mobile-ads.html?&cid=6461b2bcc86016000199a3f8&ct=web_slider'
            },
            {
                name: '動態投票 320高',
                src: 'https://ad-demo.vmfive.com/demos/showcase/2022Q2/poll_320h_static.png',
                url: 'https://ad-demo.vmfive.com/creative/mobile-ads.html?&cid=6461c8dd5ee9e700013457b5&ct=web_slider'
            },
            {
                name: '動態投票 320高(影音)',
                src: 'https://ad-demo.vmfive.com/demos/showcase/2022Q2/poll_320h_video.png',
                url: 'https://ad-demo.vmfive.com/creative/mobile-ads.html?&cid=6461cb68c86016000199a405&ct=web_slider'
            },
            {
                name: '開場動畫+320置底(影音)',
                src: 'https://d2v4tz4zvhrua.cloudfront.net/manual_uploads/20220114/icon/demopage_icon__320h_expandable.png',
                url: 'https://ad-demo.vmfive.com/creative/mobile-ads.html?&cid=6461d69e5ee9e700013457c1&ct=web_slider'
            },
            {
                name: '撲克廣告(影音展開)',
                src: 'https://d2v4tz4zvhrua.cloudfront.net/manual_uploads/20220114/icon/demopage_icon_video_expand.png',
                url: 'https://ad-demo.vmfive.com/creative/mobile-ads.html?&cid=63b4da817fef4400011783fd&ct=web_slider'
            },
            {
                name: '撲克廣告(展開)',
                src: 'https://d2v4tz4zvhrua.cloudfront.net/manual_uploads/20220114/icon/demopage_icon_expand.png',
                url: 'https://ad-demo.vmfive.com/creative/mobile-ads.html?&cid=6461e2d2c86016000199a41b&ct=web_slider'
            },
            {
                name: '撲克廣告(影音)',
                src: 'https://d2v4tz4zvhrua.cloudfront.net/manual_uploads/20220114/icon/demopage_icon__pickpop_video.png',
                url: 'https://ad-demo.vmfive.com/creative/mobile-ads.html?&cid=6461e645d0a531000135e84a&ct=web_slider'
            },
            {
                name: '撲克廣告',
                src: 'https://d2v4tz4zvhrua.cloudfront.net/manual_uploads/20220114/icon/demopage_icon__pickpop.png',
                url: 'https://ad-demo.vmfive.com/creative/mobile-ads.html?&cid=6461e7685ee9e700013457eb&ct=web_slider'
            },
            {
                name: '力量之鈕(影音)',
                src: 'https://d2v4tz4zvhrua.cloudfront.net/manual_uploads/20211025/pb_video.png',
                url: 'https://ad-demo.vmfive.com/creative/mobile-ads.html?&cid=6461ee4dc86016000199a42a&ct=web_slider'
            },
            {
                name: '力量之鈕',
                src: 'https://d2v4tz4zvhrua.cloudfront.net/manual_uploads/20211025/pb.png',
                url: 'https://ad-demo.vmfive.com/creative/mobile-ads.html?&cid=6461f7645ee9e700013457f7&ct=web_slider'
            },
            {
                name: '問答廣告2.0',
                src: 'https://ad-demo.vmfive.com/demos/showcase/2021_Q3_newformat_demo/icons/demopage_icon_qa2.0.png',
                url: 'https://ad-demo.vmfive.com/creative/mobile-ads.html?&cid=6461ffa5c86016000199a43e&ct=web_slider'
            },
            {
                name: '魅力四射',
                src: 'https://ad-demo.vmfive.com/demos/showcase/2021_Q2_newformat_demo/icons/demopage_icon_breakout.png',
                url: 'https://ad-demo.vmfive.com/creative/mobile-ads.html?&cid=6462e4705ee9e70001345818&ct=web_slider'
            },
            {
                name: '全面啟動(影音)',
                src: 'https://ad-demo.vmfive.com/demos/showcase/2021_Q2_newformat_demo/icons/demopage_icon_liquid_video.png',
                url: 'https://ad-demo.vmfive.com/creative/mobile-ads.html?&cid=6462f1375ee9e7000134581a&ct=web_slider'
            },
            {
                name: '全面啟動',
                src: 'https://ad-demo.vmfive.com/demos/showcase/2021_Q2_newformat_demo/icons/demopage_icon_liquid.png',
                url: 'https://ad-demo.vmfive.com/creative/mobile-ads.html?&cid=6462f252c86016000199a45d&ct=web_slider'
            },
            {
                name: '車庫視界(影音)',
                src: 'https://ad-demo.vmfive.com/demos/showcase/2021_Q2_newformat_demo/icons/demopage_icon_garage_video.png',
                url: 'https://ad-demo.vmfive.com/creative/mobile-ads.html?&cid=6465d40b9388e90001566206&ct=web_slider'
            },
            {
                name: '車庫視界',
                src: 'https://ad-demo.vmfive.com/demos/showcase/2021_Q2_newformat_demo/icons/demopage_icon_garage.png',
                url: 'https://ad-demo.vmfive.com/creative/mobile-ads.html?&cid=646c25567c0157000174c6e6&ct=web_slider'
            },
            {
                name: '自由落體(影音)',
                src: 'https://ad-demo.vmfive.com/demos/showcase/2021Q1_adformats/icons/demopage_icon_fallen_video.png',
                url: 'https://ad-demo.vmfive.com/creative/mobile-ads.html?&cid=646300235ee9e70001345827&ct=web_slider'
            },
            {
                name: '自由落體',
                src: 'https://ad-demo.vmfive.com/demos/showcase/2021Q1_adformats/icons/demopage_icon_fallen.png',
                url: 'https://ad-demo.vmfive.com/creative/mobile-ads.html?&cid=646300d0d0a531000135e89b&ct=web_slider'
            },
            {
                name: '左右為難(影音)',
                src: 'https://ad-demo.vmfive.com/demos/showcase/2021Q1_adformats/icons/demopage_icon_abrb_video.png',
                url: 'https://ad-demo.vmfive.com/creative/mobile-ads.html?&cid=64631665d0a531000135e8a9&ct=web_slider'
            },
            {
                name: '左右為難',
                src: 'https://ad-demo.vmfive.com/demos/showcase/2021Q1_adformats/icons/demopage_icon_abrb.png',
                url: 'https://ad-demo.vmfive.com/creative/mobile-ads.html?&cid=64631976d0a531000135e8ab&ct=web_slider'
            },
            {
                name: '幻燈片(影音)',
                src: 'https://ad-demo.vmfive.com/demos/showcase/2020Q4_adformats/icons/demopage_icon_strips_video.png',
                url: 'https://ad-demo.vmfive.com/creative/mobile-ads.html?&cid=64631dd65ee9e70001345832&ct=web_slider'
            },
            {
                name: '幻燈片',
                src: 'https://ad-demo.vmfive.com/demos/showcase/2020Q4_adformats/icons/demopage_icon_strips.png',
                url: 'https://ad-demo.vmfive.com/creative/mobile-ads.html?&cid=6465dd6f08b1d0000182dbea&ct=web_slider'
            },
            {
                name: '問答廣告',
                src: 'https://ad-demo.vmfive.com/demos/showcase/2020_Q3adformats/icons/demopage_icon_question.png',
                url: 'https://ad-demo.vmfive.com/creative/mobile-ads.html?&cid=6463238ad0a531000135e8b0&ct=web_slider'
            },
            {
                name: '拖曳任務',
                src: 'https://ad-demo.vmfive.com/demos/showcase/2020Q2_adformats/icons/demopage_icon__drag150.png',
                url: 'https://ad-demo.vmfive.com/creative/mobile-ads.html?&cid=64632516c86016000199a476&ct=web_slider'
            },
            {
                name: '伸縮螢幕',
                src: 'https://ad-demo.vmfive.com/demos/showcase/2020Q2_adformats/icons/demopage_icon__stretching.png',
                url: 'https://ad-demo.vmfive.com/creative/mobile-ads.html?&cid=646c296a7bd5b6000196965a&ct=web_slider'
            },
            {
                name: '摩天輪',
                src: 'https://ad-demo.vmfive.com/demos/showcase/2020Q2_adformats/icons/demopage_icon__spin.png',
                url: 'https://ad-demo.vmfive.com/creative/mobile-ads.html?&cid=64632cfdd0a531000135e8c0&ct=web_slider'
            },
            {
                name: '摩天輪 320高(影音)',
                src: 'https://ad-demo.vmfive.com/demos/showcase/2020Q2_adformats/icons/demopage_icon__spin.png',
                url: 'https://ad-demo.vmfive.com/creative/mobile-ads.html?&cid=649bbdd8bb29c000016c07d3&ct=web_slider'
            },
            {
                name: '開場動畫',
                src: 'https://ad-demo.vmfive.com/demos/showcase/2019Q4_ad_formats/resource/icons/demopage_icon_leading.png',
                url: 'https://ad-demo.vmfive.com/creative/mobile-ads.html?&cid=6463358fd0a531000135e8c9&ct=web_slider'
            },
            {
                name: '旋轉方塊',
                src: 'https://ad-demo.vmfive.com/demos/showcase/2019Q3_ad_formats/resource/icons/demopage_icon__spincubic.png',
                url: 'https://ad-demo.vmfive.com/creative/mobile-ads.html?&cid=6463375ac86016000199a48d&ct=web_slider'
            },
            {
                name: '飛梭膠卷',
                src: 'https://ad-demo.vmfive.com/demos/showcase/2019Q3_ad_formats/resource/icons/demopage_icon__film.png',
                url: 'https://ad-demo.vmfive.com/creative/mobile-ads.html?&cid=646339fd5ee9e7000134584a&ct=web_slider'
            },
            {
                name: '摺疊手冊',
                src: 'https://ad-demo.vmfive.com/demos/showcase/2019Q3_ad_formats/resource/icons/demopage_icon__brochure.png',
                url: 'https://ad-demo.vmfive.com/creative/mobile-ads.html?&cid=64633b7f5ee9e7000134584f&ct=web_slider'
            },
            {
                name: '手翻型錄',
                src: 'https://ad-demo.vmfive.com/demos/showcase/2019Q3_ad_formats/resource/icons/demopage_icon__catalog.png',
                url: 'https://ad-demo.vmfive.com/creative/mobile-ads.html?&cid=64633d80d0a531000135e8d0&ct=web_slider'
            },
            {
                name: '旋轉燈箱',
                src: 'https://ad-demo.vmfive.com/demos/showcase/2019Q3_ad_formats/resource/icons/demopage_icon__cubelightbox.png',
                url: 'https://ad-demo.vmfive.com/creative/mobile-ads.html?&cid=646342c0c86016000199a48f&ct=web_slider'
            },
            {
                name: '置底影音',
                src: 'https://ad-demo.vmfive.com/demos/showcase/2019Q3_ad_formats/resource/icons/demopage_icon__stickyvideo.png',
                url: 'https://ad-demo.vmfive.com/creative/mobile-ads.html?&cid=64880d34e53dbb0001a895ce&ct=web_slider'
            },
            {
                name: '置底無影音',
                src: 'https://ad-demo.vmfive.com/demos/showcase/2019Q3_ad_formats/resource/icons/demopage_icon__stickyvideo.png',
                url: 'https://ad-demo.vmfive.com/creative/mobile-ads.html?&cid=6465cf5d9388e900015661fe&ct=web_sidekick'
            },
            {
                name: '切入廣告',
                src: 'https://ad-demo.vmfive.com/demos/showcase/2019Q4_ad_formats/resource/icons/demopage_icon_drivein.png',
                url: 'https://ad-demo.vmfive.com/creative/mobile-ads.html?&cid=6463491fc86016000199a491&ct=web_slider'
            },
            {
                name: '天文台',
                src: 'https://ad-demo.vmfive.com/demos/showcase/2019Q4_ad_formats/resource/icons/demopage_icon_observatory.png',
                url: 'https://ad-demo.vmfive.com/creative/mobile-ads.html?&cid=6465dad6e7e6cd000154ba6b&ct=web_slider'
            },
            {
                name: '拉霸',
                src: 'https://ad-demo.vmfive.com/demos/showcase/2019Q4_ad_formats/resource/icons/demopage_icon_slotmachine.png',
                url: 'https://ad-demo.vmfive.com/creative/mobile-ads.html?&cid=64634fb3d0a531000135e8d6&ct=web_slider'
            },
            {
                name: '藝廊轉盤',
                src: 'https://ad-demo.vmfive.com/demos/showcase/2019Q3_ad_formats/resource/icons/demopage_icon__gallery.png',
                url: 'https://ad-demo.vmfive.com/creative/mobile-ads.html?&cid=646353d8c86016000199a49d&ct=web_slider'
            },
            {
                name: '步進式廣告',
                src: 'https://ad-demo.vmfive.com/demos/showcase/2019Q3_ad_formats/resource/icons/demopage_icon__zoom.png',
                url: 'https://ad-demo.vmfive.com/creative/mobile-ads.html?&cid=64643c78d0a531000135e8df&ct=web_slider'
            },
            {
                name: '跑馬燈',
                src: 'https://ad-demo.vmfive.com/demos/showcase/2019Q3_ad_formats/resource/icons/demopage_icon__marquee.png',
                url: 'https://ad-demo.vmfive.com/creative/mobile-ads.html?&cid=64643d8fc86016000199a4b9&ct=web_slider'
            },
            {
                name: '俄羅斯方塊',
                src: 'https://ad-demo.vmfive.com/demos/showcase/2019Q3_ad_formats/resource/icons/demopage_icon__tetris.png',
                url: 'https://ad-demo.vmfive.com/creative/mobile-ads.html?&cid=64644187c86016000199a4bd&ct=web_slider'
            }
        ],
        Interstitial: [
            {
                name: '滑軌廣告',
                src: 'https://d2v4tz4zvhrua.cloudfront.net/gallery-icon/scroll-snapping_interstitial.png',
                url: 'https://ad-demo.vmfive.com/creative/mobile-ads.html?&cid=639fd6202d2d7b000130c90b&ct=web_interstitial',
                isNew: true
            },
            {
                name: '滑軌廣告(影音)',
                src: 'https://d2v4tz4zvhrua.cloudfront.net/gallery-icon/scroll-snapping-video_interstitial.png',
                url: 'https://ad-demo.vmfive.com/creative/mobile-ads.html?&cid=639fd2df77107300018df348&ct=web_impressive',
                isNew: true
            },
            {
                name: 'FullHouse(原萃)',
                src: 'https://d2v4tz4zvhrua.cloudfront.net/manual_uploads/20220114/icon/demopage_icon__fullhouse_Video.png	',
                url: 'https://ad-demo.vmfive.com/creative/mobile-ads.html?&cid=646452d65ee9e700013458a6&ct=web_slider'
            },
            {
                name: 'FullHouse(Dyson)',
                src: 'https://d2v4tz4zvhrua.cloudfront.net/manual_uploads/20220114/icon/demopage_icon__fullhouse.png',
                url: 'https://ad-demo.vmfive.com/creative/mobile-ads.html?&cid=6467277708b1d0000182dc68&ct=web_interstitial'
            }
        ],
        Showcase: [
            {
                name: '酒',
                src: 'https://ad-demo.vmfive.com/demos/showcase/2020Q2_adformats/icons/demopage_icon__aac.png',
                url: 'https://ad-demo.vmfive.com/creative/mobile-ads.html?&cid=627a0325f3779f00017c1fb3&ct=web_slider'
            },
            {
                name: '汽車',
                src: 'https://ad-demo.vmfive.com/demos/showcase/2020Q2_adformats/icons/demopage_icon__aac.png',
                url: 'https://ad-demo.vmfive.com/creative/mobile-ads.html?&cid=63b4da817fef4400011783fd&ct=web_slider'
            },
            {
                name: '海尼根',
                src: 'https://ad-demo.vmfive.com/demos/showcase/2020Q2_adformats/icons/demopage_icon__aac.png',
                url: 'https://ad-demo.vmfive.com/creative/mobile-ads.html?&cid=64463a59e6481100018bb05c&ct=web_interstitial'
            },
            {
                name: '肯德基',
                src: 'https://ad-demo.vmfive.com/demos/showcase/2020Q2_adformats/icons/demopage_icon__aac.png',
                url: 'https://ad-demo.vmfive.com/creative/mobile-ads.html?&cid=646c7233ebd31f0001229bbb&ct=web_slider'
            },
            {
                name: 'Adidas',
                src: 'https://ad-demo.vmfive.com/demos/showcase/2020Q2_adformats/icons/demopage_icon__aac.png',
                url: 'https://ad-demo.vmfive.com/creative/mobile-ads.html?&cid=64741963ebd31f0001229d3c&ct=web_slider'
            }
        ],
        Pc: [
            {
                name: 'Headline',
                src: 'https://ad-demo.vmfive.com/demos/showcase/2020Q2_adformats/icons/demopage_icon_headline.png',
                url: 'https://ad-demo.vmfive.com/creative/desktop-ads.html?&cid=647423397bd5b6000196981a&ct=web_billboard'
            },
            {
                name: 'SildBar',
                src: 'https://ad-demo.vmfive.com/demos/showcase/2020Q2_adformats/icons/demopage_icon_inread.png',
                url: 'https://ad-demo.vmfive.com/creative/desktop-ads.html?&cid=647425097c0157000174c82b&ct=web_sidekick'
            }
        ]
    }
};

const getters = {
    adFormats: (state) => state.adFormats
};

const mutations = {};

const actions = {};

export default {
    state,
    getters,
    mutations,
    actions
};
