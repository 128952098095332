function loadJs(url) {
  return new Promise((resolve, reject) => {
    const script = document.createElement("script")
    script.src = url
    script.onload = resolve
    script.onerror = reject
    document.body.appendChild(script)
  });
}
async function asyncLoadJs(para) {
  await loadJs('https://d2v4tz4zvhrua.cloudfront.net/production/dist/2021-07-19-1626682267011/common/TemplateBuilder.min.js').catch(err => {})
}
asyncLoadJs().then(() => {
  let template = new TemplateBuilder({
    cdn: 'https://d2v4tz4zvhrua.cloudfront.net/production/dist/2021-07-20-1626770813049',
    plugins: [],
    adformats: [
      'BannerContainer',
    ],
    components: [
      'Spinning', 'NewCloseButton', 'DynamicWeather'
    ],
    develop: false,
    developRelativePath: '../../../..',
    Tracking: [
      function (self) {
        return {
          option: {
            el: '#vmfive-ad-unit-container-55667788',
            'request_id': '[request_id]',
            wantedTrackingList: [
              'impression', 'close', 'video_percentage', 'video_progress', 'custom', 'view', {
                expand: '1'
              }, {
                collapse: '1'
              }, {
                accept_invitation: '1'
              }
            ],
            fbPixelId: '748409338986541',
            wantedFbPixelList: [
              'impression', 'close', 'video_percentage', 'video_progress', 'custom', 'view', {
                expand: '1'
              }, {
                collapse: '1'
              }, {
                accept_invitation: '1'
              }
            ],
          },
          demoOption: {
            'text:3rd_party_track_url': '',
          }
        }
      }
    ],
    AdBox: [
      function (self) {
        return {
          refName: 'AdBox',
          option: {
            boxStyle: 'fixed',
            flexibleHeightRate: 1.17,
            plugins: [
              'https://man.vm5apis.com/dist/MixPlayer/1.4.0/MixPlayer.js',
              'https://man.vm5apis.com/dist/jquery/2.1.3/jquery.min.js',
              'https://man.vm5apis.com/dist/hammer.js/2.0.8/hammer.min.js',
              'https://man.vm5apis.com/dist/jquery.hammerjs/2.0.0/jquery.hammer.js',
              'https://man.vm5apis.com/dist/raf.js',
              'https://man.vm5apis.com/dist/anime.js/3.0.1/anime.min.js',
              'https://man.vm5apis.com/dist/odometer.js/0.4.8/odometer.min.js'
            ]
          },
          demoOption: {
            'request_id': '55667788',
          }
        }
      },
    ],
    BaseAdUnit: [
      function (self) {
        return {
          adformatName: 'BannerContainer',
          refName: 'BannerContainer',
          option: {
            el: self.refs['AdBox'].practicalAdContainer,
            enableClipPath: false,
            disableCta: true,
            TrackingHelper: self.TrackingHelper,
            AdBox: self.refs['AdBox'],
            'text:banner_ratio': '32:15',
          },
          demoOption: {
            'cta:default': 'https://adison.com.tw/landing/?utm_source=adison_ad_cta',
            'text:banner_bottom_offset': ''
          }
        };
      },
    ],
    OnBaseAdUnitPrepared: [
      function (self) {
        self.refs['onSpinningPrepared'] = function (spinning) {
          spinning.mediasWrap.querySelectorAll('.vmfive-spinning-item').forEach((item, i) => {
            let dynamicWeatherEl = new DynamicWeather({
              ...{
                el: item,
                'request_id': self.refs['BannerContainer'].config.request_id + `-${i+1}`,
                'track_url': JSON.stringify(self.TrackingHelper.getTrackingList()),

                enableUVI: false,
                enableAQI: false,
                enablePM2Dot5: false,
                enableTemperature: true,
                enableRainfall: false,

                'text:top_percentage': '',
                'text:bottom_percentage': '9',
                'text:left_percentage': '',
                'text:right_percentage': '25.5',

                'text:dynamic_weather_percentage': '50%',
                'text:dynamic_weather_title': '',
                'text:dynamic_weather_text_right': '',

                AdBox: self.refs['AdBox'],
              }
            });
          });
        }
      },
      function (self) {
        self.refs['onHiddenWeatherPrepared'] = function (weather) {
          let spinningEl = new Spinning({
            el: self.refs['BannerContainer'].adContainerView,
            'request_id': self.refs['BannerContainer'].config.request_id,
            hintImage: 'https://d1v69ngg41pvl6.cloudfront.net/dist/image/spinning_hint.png',
            'dfp_url': '[dfp_url]',
            'cta_prefix_url': '[cta_prefix_url]',
            'cta:default': 'https://adison.com.tw/landing/?utm_source=adison_ad_cta',
            'text:expand_close_button_horizontal_alignment': '[text:expand_close_button_horizontal_alignment]',
            'text:disable_expand_close_button_fadein': '[text:disable_expand_close_button_fadein]',
            'text:cta_in_new_window': '[text:cta_in_new_window]',
            'text:spinning_media_click_expand': '[text:spinning_media_click_expand]',
            'text:spinning_autoplay_interval': '3000',
            'text:banner_top_offset': `[text:banner_top_offset]`,
            'text:banner_bottom_offset': `[text:banner_bottom_offset]`,
            'text:expand_auto_rotate': '[text:expand_auto_rotate]',
            'text:banner_ratio': '32:15',

            TrackingHelper: self.TrackingHelper,
            AdBox: self.refs['AdBox'],
            medias: self.spinningMedias,
            expand: self.spinningExpand,
            onprepared: [
              function (com) {
                self.refs['onSpinningPrepared'](com);
              }
            ],
            onhammer: [
              function (com) {
                self.refs['NewCloseButton'].hideButton();
              },
            ],
            onExpandBanner: [
              function (com) {
                self.refs['NewCloseButton'].hideButton();
              },
            ],
            oncloseexpanded: [
              function (com) {
                self.refs['NewCloseButton'].showButton();
              },
            ]
          });
        }
      },
      function (self) {
        return {
          componentName: 'DynamicWeather',
          refName: 'HiddenDynamicWeather',
          option: {
            el: self.refs['BannerContainer'].adContainerView,
            enableUVI: false,
            enableAQI: false,
            enablePM2Dot5: false,
            enableTemperature: true,
            enableRainfall: false,
            'text:dynamic_weather_title': '',
            'text:dynamic_weather_text_right': '',
            AdBox: self.refs['AdBox'],
            onprepared: [
              function (com) {
                self.refs['HiddenDynamicWeather'].rootView.style.display = 'none';
                self.refs['HiddenDynamicWeather'].rootView.style.opacity = '0';

                let medias_less = '[' +
                  '{"image": "https://ad-demo.vmfive.com/demos/showcase/20210809_dynamic_info/spin-weather/medias_less_1.png", "ctaURL":"https://adison.com.tw/landing/?utm_source=adison_ad_cta"},' +
                  '{"image": "https://ad-demo.vmfive.com/demos/showcase/20210809_dynamic_info/spin-weather/medias_less_2.png", "ctaURL":"https://adison.com.tw/landing/?utm_source=adison_ad_cta"},' +
                  '{}]';
                let medias_less_expand = '[' +
                  '{"image": "https://ad-demo.vmfive.com/demos/showcase/20210809_dynamic_info/spin-weather/medias_less_expand_1.png", "ctaURL":"https://adison.com.tw/landing/?utm_source=adison_ad_cta"},' +
                  '{"image": "https://ad-demo.vmfive.com/demos/showcase/20210809_dynamic_info/spin-weather/medias_less_expand_2.png", "ctaURL":"https://adison.com.tw/landing/?utm_source=adison_ad_cta"},' +
                  '{}]';
                let medias_over = '[' +
                  '{"image": "https://ad-demo.vmfive.com/demos/showcase/20210809_dynamic_info/spin-weather/medias_over_1.png", "ctaURL":"https://adison.com.tw/landing/?utm_source=adison_ad_cta"},' +
                  '{"image": "https://ad-demo.vmfive.com/demos/showcase/20210809_dynamic_info/spin-weather/medias_over_2.png", "ctaURL":"https://adison.com.tw/landing/?utm_source=adison_ad_cta"},' +
                  '{}]';
                let medias_over_expand = '[' +
                  '{"image": "https://ad-demo.vmfive.com/demos/showcase/20210809_dynamic_info/spin-weather/medias_over_expand_1.png", "ctaURL":"https://adison.com.tw/landing/?utm_source=adison_ad_cta"},' +
                  '{"image": "https://ad-demo.vmfive.com/demos/showcase/20210809_dynamic_info/spin-weather/medias_over_expand_2.png", "ctaURL":"https://adison.com.tw/landing/?utm_source=adison_ad_cta"},' +
                  '{}]';

                let switchTemperature = "25";
                switchTemperature = isNaN(switchTemperature) || switchTemperature === 0 ? 18 :
                  switchTemperature;

                if (self.refs['HiddenDynamicWeather'].currentTemperature < switchTemperature) {
                  self.spinningMedias = medias_less;
                  self.spinningExpand = medias_less_expand;
                } else {
                  self.spinningMedias = medias_over;
                  self.spinningExpand = medias_over_expand;
                }

                self.refs['HiddenDynamicWeather'].rootView.remove();
                self.refs['onHiddenWeatherPrepared'](com);
              }
            ]
          }
        }
      },
      function (self) {
        return {
          componentName: 'NewCloseButton',
          refName: 'NewCloseButton',
          option: {
            el: self.refs['AdBox'].boxWrapper,
            TrackingHelper: self.TrackingHelper,
            closeTarget: self.refs['AdBox'].boxWrapper,
          },
          demoOption: {
            'text:show_close_mode': 'fadein',
          }
        }
      }
    ]
  });
})