function loadJs(url) {
    return new Promise((resolve, reject) => {
        const script = document.createElement("script")
        script.src = url
        script.onload = resolve
        script.onerror = reject
        document.body.appendChild(script)
    });
}
async function asyncLoadJs(para) {
    await loadJs('https://cdnjs.cloudflare.com/ajax/libs/jquery/2.1.3/jquery.min.js').catch(err => { })
    await loadJs('https://man.vm5apis.com/dist/raf.js').catch(err => { })
    await loadJs('https://man.vm5apis.com/dist/anime.js/3.0.1/anime.min.js').catch(err => { })
    await loadJs('https://d2v4tz4zvhrua.cloudfront.net/production/dist/2020-04-20-1587354435084/common/TemplateBuilder.min.js').catch(err => { })
}
asyncLoadJs().then(() => {
    let template = new TemplateBuilder({
        cdn: 'https://d2v4tz4zvhrua.cloudfront.net/production/dist/2020-04-20-1587354435084',
        plugins: [
        ],
        adformats: [
            'BannerContainer',
        ],
        components: [
            'Stretching', 'CloseButton'
        ],
        develop: false,
        Tracking: [
            function (self) {
                return {
                    option: {
                        el: '#vmfive-ad-unit-container-55667788',
                        'request_id': '[request_id]',
                        wantedTrackingList: [
                            'impression', 'close', 'video_percentage', 'video_progress', 'custom', 'view'
                        ],
                        fbPixelId: '',
                        wantedFbPixelList: [],
                    },
                    demoOption: {
                        'text:3rd_party_track_url': '',
                    }
                }
            }
        ],
        // {video_percentage:'1'}
        BaseAdUnit: [
            function (self) {
                return {
                    adformatName: 'BannerContainer',
                    refName: 'BannerContainer',
                    option: {
                        el: '#vmfive-ad-unit-container-55667788',
                        enableClipPath: false,
                        disableCta: true,
                        TrackingHelper: self.TrackingHelper,
                    },
                    demoOption: {
                        'cta:default': 'https://adison.com.tw/landing/?utm_source=adison_ad_cta',
                        'text:banner_ratio': '32:5',
                    }
                };
            },
        ],
        OnBaseAdUnitPrepared: [
            // demos/showcase/2020_Q2/gsk/resources/gsk_banner.png
            function (self) {
                return {
                    componentName: 'Stretching',
                    refName: 'Stretching',
                    option: {
                        el: self.refs['BannerContainer'].adContainerView,
                        'request_id': '[request_id]',
                        'text:auto_down': 'false',
                        onLarge: [
                            function () {
                                self.refs['CloseButton'].closeEl.style.display = 'none';
                                self.refs['CloseButton'].closeBeautify.style.display = 'none';
                            }
                        ],
                        onMiddle: [
                            function () {
                                self.refs['CloseButton'].closeEl.style.display = 'none';
                                self.refs['CloseButton'].closeBeautify.style.display = 'none';
                            }
                        ],
                        onSmall: [
                            function () {
                                self.refs['CloseButton'].closeEl.style.display = 'block';
                                self.refs['CloseButton'].closeBeautify.style.display = 'block';
                            }
                        ],
                        TrackingHelper: self.TrackingHelper,
                    },
                    demoOption: {
                        'text:banner_ratio': '32:28',
                        'image:banner_one': 'https://ad-demo.vmfive.com/demos/showcase/2020_Q2/trolls/resources/trolls_100h_banner_01.gif',
                        'image:banner_two': 'https://ad-demo.vmfive.com/demos/showcase/2020_Q2/trolls/resources/trolls_150h_banner_02.png',
                        'image:banner_three': 'https://ad-demo.vmfive.com/demos/showcase/2020_Q2/trolls/resources/trolls_50h_banner_03.png',
                        'video:video1:mp4': 'https://ad-demo.vmfive.com/demos/showcase/2020_Q2/trolls/resources/trolls_video.mp4',
                        'cta:default': 'https://adison.com.tw/landing/?utm_source=adison_ad_cta',
                        'image:cover': 'https://ad-demo.vmfive.com/demos/showcase/2020_Q2/trolls/resources/trolls_coverimage.png',
                    }
                }
            },
            function (self) {
                return {
                    componentName: 'CloseButton',
                    refName: 'CloseButton',
                    option: {
                        el: self.refs['BannerContainer'].adContainerView,
                        'request_id': '[request_id]',
                        TrackingHelper: self.TrackingHelper,
                    },
                    demoOption: {
                        'text:show_close_mode': 'fadein',
                        'text:close_out_container': 'true',
                        'text:close_button_horizontal_alignment': 'right',
                        'text:beautify_percent': '80',
                    }
                }
            },
            function (self) {
                self.refs['CloseButton'].closeEl.style.display = 'none';
                self.refs['CloseButton'].closeBeautify.style.display = 'none';
            }
        ]
    });
})