
function loadJs(url) {
    return new Promise((resolve, reject) => {
        const script = document.createElement("script")
        script.src = url
        script.onload = resolve
        script.onerror = reject
        document.body.appendChild(script)
    });
}
async function asyncLoadJs(para) {
    // 可以改成 foreach方式
    await loadJs('https://d1v69ngg41pvl6.cloudfront.net/dist/odometer.js/0.4.8/odometer.min.js').catch(err => { })
    await loadJs('https://d2v4tz4zvhrua.cloudfront.net/production/dist/2020-04-06-1586158835626/common/TemplateBuilder.min.js').catch(err => { })
}
asyncLoadJs().then(() => {
    let template = new TemplateBuilder({
        cdn: 'https://d2v4tz4zvhrua.cloudfront.net/production/dist/2020-04-06-1586158835626',
        plugins: [
            'https://man.vm5apis.com/dist/anime.js/2.0.2/anime.min.js'
        ],
        adformats: [
            'ImpressiveFlyer',
        ],
        components: [
            'Flyer',
        ],
        develop: false,
        Tracking: [
            function (self) {
                return {
                    option: {
                        el: '#vmfive-ad-unit-container-55667788',
                        'request_id': '[request_id]',
                        wantedTrackingList: [
                            'impression', 'close', 'video_percentage', 'video_progress', 'custom', 'view'
                        ],
                        fbPixelId: '',
                        wantedFbPixelList: [],
                    },
                    demoOption: {
                        'text:3rd_party_track_url': '',
                    }
                }
            }
        ],
        BaseAdUnit: [
            function (self) {
                return {
                    adformatName: 'ImpressiveFlyer',
                    refName: 'ImpressiveFlyer',
                    option: {
                        el: '#vmfive-ad-unit-container-55667788',
                        'request_id': '[request_id]',

                        keepCheckingPosition: false,
                        setLinkView: true,
                        onlinkview: [],
                        TrackingHelper: self.TrackingHelper,
                    },
                    demoOption: {
                        'video:video1:mp4': `https://ad-demo.vmfive.com/demos/showcase/20200224_flyer/resource/onward_30s_03.mp4`,
                        'cta:default': 'https://adison.com.tw/landing/',
                        'cta:video1': 'https://adison.com.tw/landing/',

                        'text:enter_direction': 'left',
                        'image:image1': 'https://ad-demo.vmfive.com/demos/showcase/20200224_flyer/resource/onward_flyer_bg.gif',
                        'image:foreground_image': `https://ad-demo.vmfive.com/demos/showcase/20200224_flyer/resource/onward_flyer_fore.gif`,
                        'image:cover': '',
                        'text:align_video': 'top',
                        'text:is_fixed': '',
                        'text:scale': '',
                        'text:richmedia_bg_url': ``,
                        richmedia_bg_offset_top_percentage: 0,

                        'text:view_time': '',
                        'text:view_percentage': '',
                        'text:logo_vertical_position': '',
                        'text:logo_horizontal_position': '',
                        'text:richmedia_bg_url': ``,

                    }
                };
            }
        ],
    });
})