function loadJs(url) {
  return new Promise((resolve, reject) => {
    const script = document.createElement("script")
    script.src = url
    script.onload = resolve
    script.onerror = reject
    document.body.appendChild(script)
  });
}
async function asyncLoadJs(para) {
  await loadJs('https://d2v4tz4zvhrua.cloudfront.net/production/dist/2021-07-19-1626682267011/common/TemplateBuilder.min.js').catch(err => {})
}
asyncLoadJs().then(() => {
  let template = new TemplateBuilder({
    cdn: 'https://d2v4tz4zvhrua.cloudfront.net/production/dist/2021-07-06-1625564994168',
    plugins: [],
    adformats: [
      'ImpressiveStatic',
    ],
    components: [
      'DynamicCountdown'
    ],
    develop: false,
    developRelativePath: '../../../../',
    Tracking: [
      function (self) {
        return {
          option: {
            el: '#vmfive-ad-unit-container-55667788',
            'request_id': '[request_id]',
            wantedTrackingList: [
              'impression', 'custom',
            ],
            fbPixelId: '748409338986541',
            wantedFbPixelList: [
              'impression', 'custom',
            ],
          },
          demoOption: {
            'text:3rd_party_track_url': '',
          }
        }
      }
    ],
    AdBox: [
      function (self) {
        return {
          refName: 'AdBox',
          option: {
            boxStyle: 'impressive',
            plugins: [
              'https://man.vm5apis.com/dist/odometer.js/0.4.8/odometer.min.js'
            ]
          },
          demoOption: {
            'request_id': '55667788',
          }
        }
      }
    ],
    BaseAdUnit: [
      function (self) {
        return {
          adformatName: 'ImpressiveStatic',
          refName: 'ImpressiveStatic',
          option: {
            el: self.refs['AdBox'].practicalAdContainer,
            AdBox: self.refs['AdBox'],
            TrackingHelper: self.TrackingHelper,
          },
          demoOption: {
            'text:top_margin': '',

            'cta:default': 'https://adison.com.tw/landing/?utm_source=adison_ad_cta',
            'image:image1': 'https://ad-demo.vmfive.com/demos/showcase/20210809_dynamic_info/countdown/impressive-countdown/image1.png',
            'text:scale': .9,
            // 'text:richmedia_bg_url': 'https://d2v4tz4zvhrua.cloudfront.net/manual_uploads/20190305/resource/volkswagen/800x1200/800x1200.html',
            'text:richmedia_bg_url': '',

            videoScale: '16:9',

            'text:logo_vertical_position': '',
            'text:logo_horizontal_position': '',

            // keepCheckingPosition: true,

            onctaclicked: [
              function (comp, data) {
                console.log(data);
              },
            ],
            TrackingHelper: self.TrackingHelper,
          },
        };
      }
    ],
    OnBaseAdUnitPrepared: [
      function (self) {
        let date = new Date();
        let year = date.getFullYear();
        let month = date.getMonth();
        self.refs['eventDate'] = `${year}/${month+2}/01`;
      },
      function (self) {
        return {
          componentName: 'DynamicCountdown',
          refName: 'DynamicCountdown',
          option: {
            el: self.refs['ImpressiveStatic'].bgContainer,
            AdBox: self.refs['AdBox'],
          },
          demoOption: {
            'text:event_date': self.refs['eventDate'],
            'text:top_percentage': '86',
            'text:bottom_percentage': '',
            'text:left_percentage': '16',
            'text:right_percentage': '',

            'text:countdown_prefix': '',
            'text:countdown_suffix': '',

            'text:default_font_size_ratio': '1.5',
            'text:default_font_color': '',
            'text:number_font_size_ratio': '',
            'text:number_font_color': '',
          }
        }
      },
    ]
  });
})