function loadJs(url) {
    return new Promise((resolve, reject) => {
        const script = document.createElement("script")
        script.src = url
        script.onload = resolve
        script.onerror = reject
        document.body.appendChild(script)
    });
}
async function asyncLoadJs(para) {
    await loadJs('https://cdnjs.cloudflare.com/ajax/libs/jquery/2.1.3/jquery.min.js').catch(err => {})
    await loadJs('https://man.vm5apis.com/dist/draggabilly/draggabilly.pkgd.min.js').catch(err => {})
    await loadJs('https://man.vm5apis.com/dist/anime.js/3.0.1/anime.min.js').catch(err => {})
    await loadJs('https://d2v4tz4zvhrua.cloudfront.net/production/dist/2020-04-20-1587354435084/common/TemplateBuilder.min.js').catch(err => {})
}
asyncLoadJs().then(() => {
    let template = new TemplateBuilder({
        cdn: 'https://d2v4tz4zvhrua.cloudfront.net/production/dist/2021-04-29-1619676505821',
        plugins: [
            'https://man.vm5apis.com/dist/anime.js/2.0.2/anime.min.js',
            'https://cdnjs.cloudflare.com/ajax/libs/jquery/2.1.3/jquery.min.js',
            'https://man.vm5apis.com/dist/draggabilly/draggabilly.pkgd.min.js'
        ],
        adformats: [
            'BannerContainer',
        ],
        components: [
            'DragTo', 'CloseButton'
        ],
        develop: false,
        Tracking: [
            function (self) {
                return {
                    option: {
                        el: '#vmfive-ad-unit-container-55667788',
                        'request_id': '[request_id]',
                        wantedTrackingList: [
                            'impression', 'close', 'video_percentage', 'video_progress', 'custom', 'view', 'collapse',
                            'expand', {
                                accept_invitation: '1'
                            }
                        ],
                        fbPixelId: '748409338986541',
                        wantedFbPixelList: [
                            'impression', 'close', 'video_percentage', 'video_progress', 'custom', 'view', 'collapse',
                            'expand', {
                                accept_invitation: '1'
                            }
                        ],
                    },
                    demoOption: {
                        'text:3rd_party_track_url': '',
                    }
                }
            }
        ],
        BaseAdUnit: [
            function (self) {
                return {
                    adformatName: 'BannerContainer',
                    refName: 'BannerContainer',
                    option: {
                        el: '#vmfive-ad-unit-container-55667788',
                        enableClipPath: false,
                        disableCta: false,
                        TrackingHelper: self.TrackingHelper,
                    },
                    demoOption: {
                        'cta:default': 'https://adison.com.tw/landing/?utm_source=adison_ad_cta',
                        'text:banner_ratio': '32:15',
                    }
                };
            },
        ],
        OnBaseAdUnitPrepared: [
            function (self) {
                return {
                    componentName: 'DragTo',
                    refName: 'DragTo',
                    option: {
                        el: self.refs['BannerContainer'].adContainerView,
                        'request_id': '[request_id]',
                        dragEl: {
                            url: '[image:drag_image]',
                            width: 50,
                            height: 100,
                            fadeOut: '[text:dragEl_fadeOut]'
                        },
                        targetEl: {
                            before_url: '[image:before_target]',
                            after_url: '[image:after_target]',
                            width: 50,
                            height: 100,
                            fadeOut: '[text:targetEl_fadeOut]'
                        },
                        interactionEl: {
                            image: '[image:interaction_image]',
                            mp4: '[video:interaction:mp4]'
                        },
                        ondragstart: [
                            function () {
                                if (!self.refs['DragTo'].config.disableExpand) {
                                    self.refs['CloseButton'].hideButton();
                                }
                            }
                        ],
                        TrackingHelper: self.TrackingHelper,
                        hintImage: 'https://ad-demo.vmfive.com/demos/showcase/2020_Q2/gsk/resources/drag_icon.png',
                    },
                    demoOption: {
                        dragEl: {
                            url: 'https://ad-demo.vmfive.com/demos/showcase/2020_Q2/gsk/resources/gsk_dragging.png',
                            width: 50,
                            height: 100,
                            fadeOut: 'true',
                        },
                        targetEl: {
                            before_url: 'https://ad-demo.vmfive.com/demos/showcase/2020_Q2/gsk/resources/gsk_target_before.png',
                            after_url: 'https://ad-demo.vmfive.com/demos/showcase/2020_Q2/gsk/resources/gsk_target_after.png',
                            width: 50,
                            height: 100,
                            fadeOut: 'true',
                        },
                        interactionEl: {
                            image: 'https://ad-demo.vmfive.com/demos/showcase/2020_Q2/gsk/resources/gsk_expand.png',
                            mp4: 'https://ad-demo.vmfive.com/demos/showcase/2020_Q2/gsk/resources/gsk_video.mp4',
                            coverimage: 'https://ad-demo.vmfive.com/demos/showcase/2020_Q2/gsk/resources/gsk_cover_image.png'
                        },
                        'text:banner_ratio': '32:15',

                        // 'text:drag_on_container': 'true',
                        'text:switch_background': 'true',
                        'text:fade_out_delay': '1500',
                        // 'text:disable_expand': 'true',

                        'text:background_out_delay': '2000',
                        'text:background_in_delay': '1900',

                        // 'image:drag_background': 'https://ad-demo.vmfive.com/demos/showcase/2020_Q2/gsk/resources/gsk_cover_image.png',
                        'image:drag_switch_background': 'https://ad-demo.vmfive.com/demos/showcase/2020_Q2/gsk/resources/gsk_banner.png',

                        'image:drag_background': 'https://d2v4tz4zvhrua.cloudfront.net/production/image_1612244156911.png',
                        // 'image:drag_switch_background': 'https://d2v4tz4zvhrua.cloudfront.net/production/image_1612244156911.png'

                    }
                }
            },
            function (self) {
                return {
                    componentName: 'CloseButton',
                    refName: 'CloseButton',
                    option: {
                        el: self.refs['BannerContainer'].adContainerView,
                        'request_id': '[request_id]',

                        TrackingHelper: self.TrackingHelper,
                    },
                    demoOption: {
                        'text:show_close_mode': 'fadein',
                        'text:close_button_horizontal_alignment': 'right',
                        'text:beautify_percent': '80',
                    }
                }
            },
            function (self) {
                return {
                    componentName: 'CloseButton',
                    refName: 'CloseButtonInteractionEl',
                    option: {
                        el: self.refs['DragTo'].dragInteraction,
                        'request_id': '[request_id]',
                        onExpandHelperClose: [
                            function (comp) {
                                self.refs['DragTo'].dragInteraction.parentElement.removeChild(self.refs['DragTo']
                                    .dragInteraction);
                                self.refs['CloseButton'].showButton();
                            }
                        ],
                        TrackingHelper: self.TrackingHelper,
                    },
                    demoOption: {
                        'text:show_close_mode': 'fadein',
                        'text:close_button_horizontal_alignment': 'right',
                        'text:beautify_percent': '80',
                    }
                }
            }
        ]
    });
})