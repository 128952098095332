function loadJs(url) {
    return new Promise((resolve, reject) => {
        const script = document.createElement("script")
        script.src = url
        script.onload = resolve
        script.onerror = reject
        document.body.appendChild(script)
    });
}
async function asyncLoadJs(para) {
    await loadJs('https://d2v4tz4zvhrua.cloudfront.net/production/dist/2021-07-19-1626682267011/common/TemplateBuilder.min.js').catch(err => { })
}
asyncLoadJs().then(() => {
    let template = new TemplateBuilder({
        cdn: 'https://d2v4tz4zvhrua.cloudfront.net/production/dist/2021-10-25-1635130313688',
        adformats: [
            'BannerContainer',
        ],
        components: [
            'ConveyorDoubleSlide', 'NewCloseButton', 'Recall', 'unit/BVideo'
        ],
        develop: false,
        developRelativePath: '../../../..',
        Tracking: [
            function (self) {
                return {
                    option: {
                        wantedTrackingList: [
                            'impression', 'close', 'video_percentage', 'video_progress', 'custom', 'view'
                        ],
                        fbPixelId: '748409338986541',
                        wantedFbPixelList: [
                            'impression', 'close', 'video_percentage', 'video_progress', 'custom', 'view'
                        ],
                    },
                    demoOption: {
                        'text:3rd_party_track_url': '',
                    }
                }
            }
        ],
        AdBox: [
            function (self) {
                return {
                    refName: 'AdBox',
                    option: {
                        boxStyle: 'fixed',
                        flexibleHeightRate: 1,
                        plugins: [
                            'https://man.vm5apis.com/dist/raf.js',
                            'https://man.vm5apis.com/dist/anime.js/3.0.1/anime.min.js',
                            'https://man.vm5apis.com/dist/utf8/utf8.js',
                            'https://man.vm5apis.com/dist/moment/moment.js',
                            'https://man.vm5apis.com/dist/MixPlayer/1.4.0/MixPlayer.js',
                        ]
                    },
                    demoOption: {
                        'request_id': '55667788',
                    }
                }
            },
        ],
        BaseAdUnit: [
            function (self) {
                return {
                    adformatName: 'BannerContainer',
                    refName: 'BannerContainer',
                    option: {
                        el: self.refs['AdBox'].practicalAdContainer,
                        'text:banner_ratio': '32:15',
                        videoBorderRadius: 0,
                        enableClipPath: false,
                        disableCta: false,
                        AdBox: self.refs['AdBox'],
                        TrackingHelper: self.TrackingHelper,
                    },
                    demoOption: {
                        'text:logo_horizontal_alignment': 'left',
                    }
                };
            }
        ],
        OnBaseAdUnitPrepared: [
            function (self) {
                return {
                    componentName: 'ConveyorDoubleSlide',
                    refName: 'ConveyorDoubleSlide',
                    option: {
                        el: self.refs['BannerContainer'].adContainerView,
                        'text:right_slide_from': `right`,
                        'text:left_slide_from': `left`,
                        onctaclicked: [
                            function (component, extra) {
                                self.refs['BannerContainer'].config['cta:default'] = extra.ctaURL;
                                self.refs['BannerContainer'].onCtaClicked(extra.e);
                            },
                        ],
                        AdBox: self.refs['AdBox'],
                        leftProducts: '[' +
                            '{"intro": "https://d2v4tz4zvhrua.cloudfront.net/manual_uploads/20210406/transparent.png"},' +
                            '{"intro": "https://d2v4tz4zvhrua.cloudfront.net/manual_uploads/20210406/transparent.png"},' +
                            '{"intro": "https://d2v4tz4zvhrua.cloudfront.net/manual_uploads/20210406/transparent.png"},' +
                            '{}]',
                    },
                    demoOption: {
                        'text:base_move_duration': `3000`,
                        'image:background': `https://d2v4tz4zvhrua.cloudfront.net/manual_uploads/20210928/recall/recall_drivein_bg.png`,
                        'cta:default': `https://adison.com.tw/landing?utm_source=adison_ad_cta`,
                        rightProducts: '[' +
                            '{"intro": "https://d2v4tz4zvhrua.cloudfront.net/manual_uploads/20210928/recall/recall_drivein_1.png"},' +
                            '{"intro": "https://d2v4tz4zvhrua.cloudfront.net/manual_uploads/20210928/recall/recall_drivein_2.png"},' +
                            '{"intro": "https://d2v4tz4zvhrua.cloudfront.net/manual_uploads/20210928/recall/recall_drivein_3.png"},' +
                            '{}]',
                    }
                }
            },
            function (self) {
                return {
                    componentName: 'Recall',
                    refName: 'Recall',
                    option: {
                        el: self.refs['ConveyorDoubleSlide'].adWrapperView,
                        AdBox: self.refs['AdBox'],
                        TrackingHelper: self.TrackingHelper,
                    },
                    demoOption: {
                        'image:recall_image': 'https://d2v4tz4zvhrua.cloudfront.net/manual_uploads/20210928/recall/re_call_bt.gif',
                        'text:image_ratio': '400:79',
                        'text:image_width_percentage': '50',

                        'text:button_vertical_align_percentage': '100',
                        'text:button_horizontal_align_percentage': '100',
                        'text:start_date': '2021/10/30',
                        'text:start_time': '10:00',

                        'text:end_date': '2021/10/30',
                        'text:end_time': '11:00',

                        'text:recall_name': 'Fitness Club 優惠倒數',
                        'text:recall_detail': '超低月費，兩人同行再送兩個月，免費體驗再送InBody檢測！',
                    }
                }
            },
            function (self) {
                return {
                    componentName: 'BVideo',
                    refName: 'BVideo',
                    option: {
                        el: self.refs['ConveyorDoubleSlide'].leftSlideshowView,
                        TrackingHelper: self.TrackingHelper,
                        "text:videoLoop": 'true',
                        AdBox: self.refs['AdBox'],
                        "text:percentageInViewOption": '10',
                        disableAutoplay: false,
                    },
                    demoOption: {
                        "video:video1:mp4": 'https://d2v4tz4zvhrua.cloudfront.net/manual_uploads/20210928/video.mp4',
                        'image:cover': 'https://d2v4tz4zvhrua.cloudfront.net/manual_uploads/20210928/cover.png',
                        videoScale: '16:9'
                    }
                }
            },
            function (self) {
                let videoUnitWrap = self.refs['ConveyorDoubleSlide'].leftSlideshowView.querySelector(
                    `#vmfive-ad-unit-${self.refs['ConveyorDoubleSlide'].config.request_id}`);
                let cover = self.refs['ConveyorDoubleSlide'].leftSlideshowView.querySelector('.vmfive-video-cover');
                videoUnitWrap.style.top = '20%';
                videoUnitWrap.style.width = '100.5%';
                cover.style.top = '20%';
            },
            function (self) {
                return {
                    componentName: 'NewCloseButton',
                    refName: 'NewCloseButton',
                    option: {
                        el: self.refs['AdBox'].boxWrapper,
                        AdBox: self.refs['AdBox'],
                        TrackingHelper: self.TrackingHelper,
                        closeTarget: self.refs['AdBox'].boxWrapper,
                    },
                    demoOption: {
                        'text:vertical_align_button_percentage': '0',
                        'text:horizontal_align_button_percentage': '100',
                        'text:close_out_container': 'true',
                        'text:show_close_mode': 'fadein',
                    }
                }
            }
        ]
    });
});