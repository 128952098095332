import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import Showcase from './views/Showcase.vue'
// const Showcase = () => import('./views/Showcase.vue');
import NotFound from './views/NotFound.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/index.html',
      name: 'home',
      component: Home
    },
    {
      path: '/showcase/:templateClass/:templateName.html',
      name: 'showcase',
      component: Showcase,
    },
    {
      path: '/404.html',
      name: '404',
      component: NotFound,
    },
    {
      path: '*',
      redirect: '/404.html'
    }
  ]
})