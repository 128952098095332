function loadJs(url) {
    return new Promise((resolve, reject) => {
        const script = document.createElement("script")
        script.src = url
        script.onload = resolve
        script.onerror = reject
        document.body.appendChild(script)
    });
}
async function asyncLoadJs(para) {
    await loadJs('https://cdnjs.cloudflare.com/ajax/libs/jquery/2.1.3/jquery.min.js').catch(err => { })
    await loadJs('https://man.vm5apis.com/dist/sk-vmfive.js/sk-vmfive.js').catch(err => { })
    await loadJs('https://man.vm5apis.com/dist/anime.js/3.0.1/anime.min.js').catch(err => { })
    await loadJs('https://d2v4tz4zvhrua.cloudfront.net/production/dist/2020-04-20-1587354435084/common/TemplateBuilder.min.js').catch(err => { })
}
asyncLoadJs().then(() => {
    let template = new TemplateBuilder({
        cdn: 'https://d2v4tz4zvhrua.cloudfront.net/production/dist/2020-08-03-1596450362554',
        plugins: [
        ],
        adformats: [
            'Notification',
        ],
        components: [
            'CloseButton'
        ],
        develop: false,
        Tracking: [
            function (self) {
                return {
                    option: {
                        el: '#vmfive-ad-unit-container-55667788',
                        'request_id': '[request_id]',
                        wantedTrackingList: [
                            'impression', 'close', 'custom', 'view', 'video_percentage', 'video_progress', {'expand': 1}, {'collapse': 1}
                        ],
                        fbPixelId: '',
                        wantedFbPixelList: [],
                    },
                    demoOption: {
                        'text:3rd_party_track_url': '',
                    }
                }
            }
        ],
        BaseAdUnit: [
            function (self) {
                return {
                    adformatName: 'Notification',
                    refName: 'Notification',
                    option: {
                        el: '#vmfive-ad-unit-container-55667788',
                        enableClipPath: false,
                        disableCta: false,
                        TrackingHelper: self.TrackingHelper,
                    },
                    demoOption: {
                        'text:banner_ratio': '',
                        'text:banner_top_offset': `90`,
                        'cta:default': 'https://adison.com.tw/landing/?utm_source=adison_ad_cta',
                        'text:notification_title': '摩斯漢堡 MOS Burger',
                        'text:notification_intro': '摩斯漢堡是源自日本的跨國連鎖速食餐廳，由櫻田慧與渡邊和男、吉野祥於1972年在東京創立。至今業務已擴展至全世界',
                        // 'text:notification_intro': '',
                        // 'image:container_background': 'https://ad-demo.vmfive.com/demos/showcase/2020_Q2/gsk/resources/gsk_banner.png',
                        'image:container_background': '',
                        'image:flag': 'https://ad-demo.vmfive.com/demos/showcase/2020_Q3adformats/business/resource/notification_logo.png',
                        medias:
                            '[' +
                            '{"image": "https://ad-demo.vmfive.com/demos/showcase/2020_Q3adformats/business/resource/notification_banner_1.png"},' +
                            '{"image": "https://ad-demo.vmfive.com/demos/showcase/2020_Q3adformats/business/resource/notification_banner_2.png"},' +
                            '{"image": "https://ad-demo.vmfive.com/demos/showcase/2020_Q3adformats/business/resource/notification_banner_3.png"},' +
                            // '{"text": "外層酥脆內層多汁的雞腿肉，裹上酸酸甜甜的南洋甘醋醬，再加上獨家特製的南洋沙拉醬及清脆爽口的高麗菜絲是款適合夏季食用爽口開胃的雞腿堡。"},'+
                            // '{"text": "特選小里肌製作成厚實豬排，搭配新鮮蔬菜，淋上微辣的紅咖哩醬，再以清新的檸檬柳橙醬點綴，夾入熱騰騰的薑黃米，每一口都感受滿滿的能量來源！"},'+
                            // '{"text": "香Q美味的米飯，搭配鮮蝦、干貝、墨魚等豐富的美味海鮮。"},'+
                            '{}]',
                        'text:logo_horizontal_alignment': 'right',
                        'text:logo_vertical_alignment': 'bottom',
                    }
                };
            },
        ],
        OnBaseAdUnitPrepared: [
            function (self) {
                return {
                    componentName: 'CloseButton',
                    refName: 'CloseButton',
                    option: {
                        el: self.refs['Notification'].adCloseWrap,
                        'request_id': '[request_id]',

                        TrackingHelper: self.TrackingHelper,
                    },
                    demoOption: {
                        'text:show_close_mode': 'fadein',
                        'text:close_button_horizontal_alignment': 'right',
                        'text:beautify_percent': '80',
                    }
                }
            },
        ]
    });
});