function loadJs(url) {
  return new Promise((resolve, reject) => {
    const script = document.createElement("script")
    script.src = url
    script.onload = resolve
    script.onerror = reject
    document.body.appendChild(script)
  });
}
async function asyncLoadJs(para) {
  await loadJs('https://d2v4tz4zvhrua.cloudfront.net/production/dist/2021-07-19-1626682267011/common/TemplateBuilder.min.js').catch(err => {})
}
asyncLoadJs().then(() => {
  let template = new TemplateBuilder({
    cdn: 'https://d2v4tz4zvhrua.cloudfront.net/production/dist/2021-08-06-1628238262080',
    plugins: [],
    adformats: [
      'BannerContainer',
    ],
    components: [
      'Spinning', 'NewCloseButton', 'DynamicLocation'
    ],
    develop: false,
    developRelativePath: '../../../..',
    Tracking: [
      function (self) {
        return {
          option: {
            el: '#vmfive-ad-unit-container-55667788',
            'request_id': '[request_id]',
            wantedTrackingList: [
              'impression', 'close', 'video_percentage', 'video_progress', 'custom', 'view', {
                expand: '1'
              }, {
                collapse: '1'
              }, {
                accept_invitation: '1'
              }
            ],
            fbPixelId: '748409338986541',
            wantedFbPixelList: [
              'impression', 'close', 'video_percentage', 'video_progress', 'custom', 'view', {
                expand: '1'
              }, {
                collapse: '1'
              }, {
                accept_invitation: '1'
              }
            ],
          },
          demoOption: {
            'text:3rd_party_track_url': '',
          }
        }
      }
    ],
    AdBox: [
      function (self) {
        return {
          refName: 'AdBox',
          option: {
            boxStyle: 'fixed',
            flexibleHeightRate: 1.17,
            plugins: [
              'https://man.vm5apis.com/dist/MixPlayer/1.4.0/MixPlayer.js',
              'https://man.vm5apis.com/dist/jquery/2.1.3/jquery.min.js',
              'https://man.vm5apis.com/dist/hammer.js/2.0.8/hammer.min.js',
              'https://man.vm5apis.com/dist/jquery.hammerjs/2.0.0/jquery.hammer.js',
              'https://man.vm5apis.com/dist/raf.js',
              'https://man.vm5apis.com/dist/anime.js/3.0.1/anime.min.js',
              'https://man.vm5apis.com/dist/odometer.js/0.4.8/odometer.min.js'
            ]
          },
          demoOption: {
            'request_id': '55667788',
          }
        }
      },
    ],
    BaseAdUnit: [
      function (self) {
        return {
          adformatName: 'BannerContainer',
          refName: 'BannerContainer',
          option: {
            el: self.refs['AdBox'].practicalAdContainer,
            enableClipPath: false,
            disableCta: true,
            TrackingHelper: self.TrackingHelper,
            AdBox: self.refs['AdBox'],
            'text:banner_ratio': '32:15',
          },
          demoOption: {
            'cta:default': 'https://adison.com.tw/landing/?utm_source=adison_ad_cta',
          }
        };
      },
    ],
    OnBaseAdUnitPrepared: [
      function (self) {
        self.refs['onSpinningPrepared'] = function (spinning) {
          let dynamicLocationElOptions = [{
            'text:default_font_size_ratio': `[text:default_font_size_ratio_1]`,
            'text:default_font_color': `white`,
            'text:number_font_size_ratio': `[text:number_font_size_ratio_1]`,
            'text:number_font_color': `[text:number_font_color_1]`,
            'text:location_default_background_mask': `black`,
            'text:location_1st_background_mask': `[text:location_1st_background_mask_1]`,
          }, {
            'text:default_font_size_ratio': `[text:default_font_size_ratio_2]`,
            'text:default_font_color': `white`,
            'text:number_font_size_ratio': `[text:number_font_size_ratio_2]`,
            'text:number_font_color': `[text:number_font_color_2]`,
            'text:location_default_background_mask': `black`,
            'text:location_1st_background_mask': `[text:location_1st_background_mask_2]`,
          }, {
            'text:default_font_size_ratio': `[text:default_font_size_ratio_3]`,
            'text:default_font_color': `white`,
            'text:number_font_size_ratio': `[text:number_font_size_ratio_3]`,
            'text:number_font_color': `[text:number_font_color_3]`,
            'text:location_default_background_mask': `black`,
            'text:location_1st_background_mask': `[text:location_1st_background_mask_3]`,
          }, {
            'text:default_font_size_ratio': `[text:default_font_size_ratio_4]`,
            'text:default_font_color': `white`,
            'text:number_font_size_ratio': `[text:number_font_size_ratio_4]`,
            'text:number_font_color': `[text:number_font_color_4]`,
            'text:location_default_background_mask': `black`,
            'text:location_1st_background_mask': `[text:location_1st_background_mask_4]`,
          }];
          spinning.mediasWrap.querySelectorAll('.vmfive-spinning-item').forEach((item, i) => {
            let dynamicLocationEl = new DynamicLocation({
              ...{
                el: item,
                'request_id': self.refs['BannerContainer'].config.request_id + `-${i+1}`,
                'track_url': JSON.stringify(self.TrackingHelper.getTrackingList()),
                'text:gps_list': '`{"name":"微風廣場", "address":"台北市復興南路1段39號G樓", "latitude":25.0460728, "longitude":121.5440861},`+ `{"name":"遠東百貨", "address":"台北市寶慶路32號2樓", "latitude":25.0415675, "longitude":121.509086},`+ `{"name":"美麗華", "address":"台北市敬業三路20號1樓1樓", "latitude":25.0833072, "longitude":121.5571648},`+ `{"name":"新光三越台北信義新天地A11", "address":"台北市松壽路11號2樓", "latitude":25.0365107, "longitude":121.5672966},`+ `{"name":"新光三越台北南西店", "address":"台北市南京西路12號3樓", "latitude":25.0521327, "longitude":121.5211112},`+ `{"name":" 台北SOGO復興店", "address":"台北市忠孝東路三段300號7樓", "latitude":25.0411149, "longitude":121.543058},`+ `{"name":"遠企購物中音", "address":"台北市敦化南路二段203號3樓", "latitude":25.0263019, "longitude":121.5493621},`+ `{"name":"新光三越台中中港店", "address":"台中市台灣大道三段301號3樓", "latitude":24.1651933, "longitude":120.6436913},`+ `{"name":"新光三越台南新天地", "address":"台南市西門路一段658號1樓B區", "latitude":22.9873418, "longitude":120.1991126},`+ `{"name":"高雄大遠百", "address":"高雄市三多四路21號2樓", "latitude":22.6130137, "longitude":120.3043672},`+ `{"name":"漢神巨蛋購物廣場", "address":"高雄市博愛二路777號2樓", "latitude":22.6698136, "longitude":120.3026664},`+',
                'text:search_keyword': 'Calvin Klein',
                'text:link_gotomap_cta': '',
                'text:top_percentage': '',
                'text:bottom_percentage': '0.4',
                'text:left_percentage': '46.3',
                'text:right_percentage': '',
                'text:location_click_hint': '距離<br />門市',
                'text:location_click_hint_suffix': '公里',
                'text:location_prefix': '距離最近門市約',
                'text:location_suffix': '公里',
                'text:external_gps_list': '',
                'dfp_url': '[dfp_url]',
                'cta_prefix_url': '[cta_prefix_url]',
                ctaMode: 'default',
                disableCta: false,
                TrackingHelper: self.TrackingHelper,
                onprepared: [],
                AdBox: self.refs['AdBox']
              },
              ...dynamicLocationElOptions[i]
            });
          });
        }
      },
      function (self) {
        return {
          componentName: 'Spinning',
          refName: 'Spinning',
          option: {
            el: self.refs['BannerContainer'].adContainerView,
            TrackingHelper: self.TrackingHelper,
            AdBox: self.refs['AdBox'],
            'text:banner_ratio': '32:15',
            hintImage: 'https://d1v69ngg41pvl6.cloudfront.net/dist/image/spinning_hint.png',
            onprepared: [
              function (com) {
                self.refs['onSpinningPrepared'](com);
              }
            ],
            onhammer: [
              function (com) {
                self.refs['NewCloseButton'].hideButton();
              },
            ],
            onExpandBanner: [
              function (com) {
                self.refs['NewCloseButton'].hideButton();
              },
            ],
            oncloseexpanded: [
              function (com) {
                self.refs['NewCloseButton'].showButton();
              },
            ],
          },
          demoOption: {
            'text:expand_close_button_horizontal_alignment': 'right',
            'cta:default': 'https://www.google.com/',
            'text:spinning_media_click_expand': 'false',
            medias: '[' +
              '{"image": "https://ad-demo.vmfive.com/demos/showcase/20210809_dynamic_info/location/spinning_medias_1.png", "ctaURL": "https://adison.com.tw/landing/?utm_source=adison_ad_cta"},' +
              '{"image": "https://ad-demo.vmfive.com/demos/showcase/20210809_dynamic_info/location/spinning_medias_2.png", "ctaURL": "https://adison.com.tw/landing/?utm_source=adison_ad_cta"},' +
              '{"image": "https://ad-demo.vmfive.com/demos/showcase/20210809_dynamic_info/location/spinning_medias_3.png", "ctaURL": "https://adison.com.tw/landing/?utm_source=adison_ad_cta"},' +
              '{"image": "https://ad-demo.vmfive.com/demos/showcase/20210809_dynamic_info/location/spinning_medias_4.png", "ctaURL": "https://adison.com.tw/landing/?utm_source=adison_ad_cta"},' +
              '{}]',
            expand: '[' +
              '{"image": "https://ad-demo.vmfive.com/demos/showcase/20210809_dynamic_info/location/spinning_expand_1.jpeg" , "ctaURL": "https://adison.com.tw/landing/?utm_source=adison_ad_cta"},' +
              '{"mp4": "https://ad-demo.vmfive.com/demos/showcase/20210809_dynamic_info/location/expand_2.mp4", "image": "https://ad-demo.vmfive.com/demos/showcase/20210809_dynamic_info/location/spinning_expand_2.jpeg" , "ctaURL": "https://adison.com.tw/landing/?utm_source=adison_ad_cta"},' +
              '{"image": "https://ad-demo.vmfive.com/demos/showcase/20210809_dynamic_info/location/spinning_expand_3.jpeg" , "ctaURL": "https://adison.com.tw/landing/?utm_source=adison_ad_cta"},' +
              '{"image": "https://ad-demo.vmfive.com/demos/showcase/20210809_dynamic_info/location/spinning_expand_4.jpeg" , "ctaURL": "https://adison.com.tw/landing/?utm_source=adison_ad_cta"},' +
              '{}]',
          }
        }
      },
      function (self) {
        return {
          componentName: 'NewCloseButton',
          refName: 'NewCloseButton',
          option: {
            el: self.refs['AdBox'].boxWrapper,
            TrackingHelper: self.TrackingHelper,
            closeTarget: self.refs['AdBox'].boxWrapper,
          },
          demoOption: {
            'text:close_out_container': 'true',
            'text:show_close_mode': 'fadein',
            'text:close_style': `general`, // 'fate', 'second', 'double', 'general'
          }
        }
      }
    ]
  });
})