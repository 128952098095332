<template>
    <div class="category-list">
        <router-link to="/index.html"
                     class="close"
                     tag="div"
        >
            <img src="img/arrow_left.png" alt="">
        </router-link>
        <div class="title">
            <h2>{{categoryKey[0].name}}</h2>
            <p>{{categoryKey[0].enName}}</p>
        </div>
        <div class="filter">
            <ul>
                <a v-for="(item) in listItem"
                   :key="item.index"
                   :href="item.url"
                   class="filter_li"
                   :class="{new: item.isNew }"
                   target='_blank'
                >
                     <li>
                        <img :src="item.src" :alt="item.name">
                        <h3>{{item.name}}</h3>
                    </li>
                </a>
            </ul>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
    export default {
        name: 'categoryList',
        props: {            
            'categoryKey': {
                type: Array
            }
        },
        created() {
            document.body.setAttribute('class','homebody');
        },
        computed: {
            ...mapGetters([
                'adFormats'
            ]),
            listItem: {
                get: function () {
                    return this.adFormats[this.categoryKey[0].key];
                }
            }
        },
        methods: {
        }
    }
</script>

<style scoped lang="scss">
// Import App scss
@import '../scss/app.scss';
</style>