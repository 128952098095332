
function loadJs(url) {
    return new Promise((resolve, reject) => {
        const script = document.createElement("script")
        script.src = url
        script.onload = resolve
        script.onerror = reject
        document.body.appendChild(script)
    });
}
async function asyncLoadJs(para) {
    // 可以改成 foreach方式
    await loadJs('https://man.vm5apis.com/dist/jquery/2.1.3/jquery.min.js').catch(err => { })
    await loadJs('https://man.vm5apis.com/dist/sk-vmfive.js/sk-vmfive.js').catch(err => { })
    await loadJs('https://man.vm5apis.com/dist/raf.js').catch(err => { })
    await loadJs('https://d2v4tz4zvhrua.cloudfront.net/production/dist/2020-04-20-1587354435084/common/TemplateBuilder.min.js').catch(err => { })
}
asyncLoadJs().then(() => {
    let template = new TemplateBuilder({
        cdn: 'https://d2v4tz4zvhrua.cloudfront.net/production/dist/2020-11-04-1604474561685',
        plugins: [
            'https://man.vm5apis.com/dist/anime.js/2.0.2/anime.min.js'
        ],
        adformats: [
          'ImpressiveContainer'
        ],
        components: [
          'Cloister', 'unit/BVideo'
        ],
        develop: false,
        Tracking: [
          function(self) {
            return {
              option: {
                el: '#vmfive-ad-unit-container-55667788',
                'request_id': '[request_id]',
                wantedTrackingList: [
                  'impression', 'accept_invitation', {video_percentage:'1'}, 'video_progress', 'custom',  'view'
                ],
                fbPixelId: '',
                wantedFbPixelList: [],
              },
              demoOption: {
                'text:3rd_party_track_url': '',
              }
            }
          }
        ],
        BaseAdUnit: [
            function (self) {
                return {
                    adformatName: 'ImpressiveContainer',
                    refName: 'ImpressiveContainer',
                    option: {
                        el: '#vmfive-ad-unit-container-55667788',
                        'text:logo_vertical_position': 'right',
                        'text:logo_horizontal_position': 'bottom',
                        enableExpand: false,
                        disableCta: true,
                        enableClipPath: false,
                        disableLogo: true,
                        backgroundScale: '9:16',
                        defaultScale: 0.85,
                        TrackingHelper: self.TrackingHelper,
                        onPositionToImpression: [
                          function() {
                            self.refs['Cloister'].startAutoPlay();
                          }
                        ]
                    },
                    demoOption: {
                        'cta:default': `https://adison.com.tw/landing/?utm_source=adison_ad_cta`,
                        'text:top_margin': '',
                    }
                };
            }
        ],
        OnBaseAdUnitPrepared: [
            function (self) {
                return {
                    componentName: 'Cloister',
                    refName: 'Cloister',
                    option: {
                        el: self.refs['ImpressiveContainer'].adContainerView,
                        containerRootView: self.refs['ImpressiveContainer'].rootView,
                        disableAutoPlay: true,
                        TrackingHelper: self.TrackingHelper,
                        onVideoContainerAppear: [
                          function() {
                            // console.log('onVideoContainerAppear');
                            self.refs['BVideo'].mix.handler.playOrPause(true);
                          }
                        ],
                        onVideoContainerDisappear: [
                          function() {
                            // console.log('onVideoContainerDisappear');
                            self.refs['BVideo'].mix.handler.playOrPause(false);
                          }
                        ]
                    },
                    demoOption: {
                        'cta:default': 'https://adison.com.tw/landing/?utm_source=adison_ad_cta',
                        'text:3rd_party_track_url': '',
                        'dfp_url': ``,

                        'text:video_top_margin_percentage': '',
                        'text:view_percentage': '',
                        'cta:video': 'https://adison.com.tw/landing/?utm_source=adison_ad_cta',

                        medias:
                        '['+
                        '{"image": "https://d2v4tz4zvhrua.cloudfront.net/manual_uploads/20200922/resource/with_video/vm_burger_banner_01.png", "ctaURL": "https://adison.com.tw/landing/?utm_source=adison_ad_cta"},'+
                        '{"image": "https://d2v4tz4zvhrua.cloudfront.net/manual_uploads/20200922/resource/with_video/vm_burger_banner_02.png", "ctaURL": "https://adison.com.tw/landing/?utm_source=adison_ad_cta"},'+
                        '{"image": "https://d2v4tz4zvhrua.cloudfront.net/manual_uploads/20200922/resource/with_video/vm_burger_banner_03.png", "ctaURL": "https://adison.com.tw/landing/?utm_source=adison_ad_cta"},'+
                        '{}]',
                    }
                }
            },
            function (self) {
                return {
                    componentName: 'BVideo',
                    refName: 'BVideo',
                    option: {
                        el: self.refs['Cloister'].videoContainer,
                        'text:percentageInViewOption': '',
                        'text:videoLoop': 'true',
                        disableAutoplay: true,
                        TrackingHelper: self.TrackingHelper,
                    },
                    demoOption: {
                        'video:video1:mp4': 'https://d2v4tz4zvhrua.cloudfront.net/manual_uploads/20200922/resource/with_video/vm_burger_video.mp4',
                        'video:video1:m3u8': '',
                        'image:cover': '',
                        'text:3rd_party_track_url': '',
                    }
                }
            },
        ]
    });
})
