function loadJs(url) {
    return new Promise((resolve, reject) => {
        const script = document.createElement("script")
        script.src = url
        script.onload = resolve
        script.onerror = reject
        document.body.appendChild(script)
    });
}
async function asyncLoadJs(para) {
    await loadJs('https://d2v4tz4zvhrua.cloudfront.net/production/dist/2021-07-19-1626682267011/common/TemplateBuilder.min.js').catch(err => {})
}
asyncLoadJs().then(() => {
    let template = new TemplateBuilder({
        cdn: 'https://d2v4tz4zvhrua.cloudfront.net/production/dist/2022-01-11-1641894085112',
        plugins: [],
        adformats: [
            'BannerContainer',
        ],
        components: [
            'PickPop', 'NewCloseButton', 'PhantomAnimation'
        ],
        develop: false,
        developRelativePath: '../../../..',
        Tracking: [
            function (self) {
                return {
                    option: {
                        'request_id': '[request_id]',
                        wantedTrackingList: [
                            'impression', 'close', 'custom', {
                                expand: '1'
                            }, {
                                collapse: '1'
                            }, {
                                accept_invitation: '1'
                            }
                        ],
                        fbPixelId: '748409338986541',
                        wantedFbPixelList: [
                            'impression', 'close', 'custom', {
                                expand: '1'
                            }, {
                                collapse: '1'
                            }, {
                                accept_invitation: '1'
                            }
                        ],
                    },
                    demoOption: {
                        'text:3rd_party_track_url': '',
                    }
                }
            }
        ],
        AdBox: [
            function (self) {
                return {
                    refName: 'AdBox',
                    option: {
                        boxStyle: 'fixed',
                        flexibleHeightRate: 1,
                        plugins: [
                            'https://man.vm5apis.com/dist/interact/1.10.11/interact.min.js',
                            'https://man.vm5apis.com/dist/gsap/3.6.0/gsap.min.js',
                        ]
                    },
                    demoOption: {
                        'request_id': '55667788',
                    }
                }
            },
        ],
        BaseAdUnit: [
            function (self) {
                return {
                    adformatName: 'BannerContainer',
                    refName: 'BannerContainer',
                    option: {
                        el: self.refs['AdBox'].practicalAdContainer,
                        enableClipPath: false,
                        disableCta: true,
                        TrackingHelper: self.TrackingHelper,
                        AdBox: self.refs['AdBox'],
                        'text:banner_ratio': '32:15',
                    },
                    demoOption: {},
                };
            }
        ],
        OnBaseAdUnitPrepared: [
            function (self) {
                return {
                    componentName: 'PickPop',
                    refName: 'PickPop',
                    option: {
                        el: self.refs['BannerContainer'].adContainerView,
                        AdBox: self.refs['AdBox'],
                        TrackingHelper: self.TrackingHelper,
                        hasVideo: false,
                        onExpand: [
                            function (comp) {
                                self.refs['PhantomAnimation'].showIframe();
                                self.refs['NewCloseButton'].hideButton();
                            }
                        ],
                        onExpandEnd: [
                            function (comp) {
                                self.refs['NewCloseButtonExpand'].showButton();
                            }
                        ],
                        onCollapse: [
                            function (comp) {
                                self.refs['PhantomAnimation'].hideIframe();
                                self.refs['NewCloseButtonExpand'].hideButton();
                            }
                        ],
                        onCollapseEnd: [
                            function (comp) {
                                self.refs['NewCloseButton'].showButton();
                            }
                        ]
                    },
                    demoOption: {
                        'text:disable_auto_play': 'true',
                        'image:pickpop_image': 'https://d2v4tz4zvhrua.cloudfront.net/manual_uploads/20220114/Pickpop_Static/background.png',
                        'image:expand_image': 'https://d2v4tz4zvhrua.cloudfront.net/manual_uploads/20210406/transparent.png',
                        medias: '[' +
                            '{"image": "https://d2v4tz4zvhrua.cloudfront.net/manual_uploads/20220114/Pickpop_Static/banner_01.png", "ctaURL": "https://vmfive.com/?utm_source=vmfive_ad_cta"},' +
                            '{"image": "https://d2v4tz4zvhrua.cloudfront.net/manual_uploads/20220114/Pickpop_Static/banner_02.png", "ctaURL": "https://vmfive.com/?utm_source=vmfive_ad_cta"},' +
                            '{"image": "https://d2v4tz4zvhrua.cloudfront.net/manual_uploads/20220114/Pickpop_Static/banner_03.png", "ctaURL": "https://vmfive.com/?utm_source=vmfive_ad_cta"},' +
                            '{}]',
                    }
                }
            },
            function (self) {
                return {
                    componentName: 'PhantomAnimation',
                    refName: 'PhantomAnimation',
                    option: {
                        el: self.refs['PickPop'].expandContainer,
                        AdBox: self.refs['AdBox'],
                    },
                    demoOption: {
                        'text:iframe_name': 'afternoon',
                        'text:iframe_url:preload:phantom_animation': 'https://d2v4tz4zvhrua.cloudfront.net/leading_material/20220110_afternoon_milktea/afternoon_milk+tea.html',
                        'text:iframe_play_mode': 'static',
                    }
                }
            },
            function (self) {
                return {
                    componentName: 'NewCloseButton',
                    refName: 'NewCloseButton',
                    option: {
                        el: self.refs['AdBox'].boxWrapper,
                        closeTarget: self.refs['AdBox'].boxWrapper,
                        TrackingHelper: self.TrackingHelper,
                        unique_name: 'default',
                        AdBox: self.refs['AdBox'],
                    },
                    demoOption: {
                        'request_id': '55667788',
                        'text:show_close_mode': 'fadein',
                        'text:vertical_align_button_percentage': '0',
                        'text:horizontal_align_button_percentage': '100',
                        'text:close_out_container': 'true',
                    }
                }
            },
            function (self) {
                return {
                    componentName: 'NewCloseButton',
                    refName: 'NewCloseButtonExpand',
                    option: {
                        el: self.refs['AdBox'].boxWrapper,
                        'request_id': '55667788-expand',
                        unique_name: 'expand',
                        AdBox: self.refs['AdBox'],
                        'cta:custom_close': `[cta:custom_close_expand]`,
                        'text:vertical_align_button_percentage': '[text:vertical_align_button_percentage_expand]',
                        'text:horizontal_align_button_percentage': '[text:horizontal_align_button_percentage_expand]',
                        'text:show_close_mode': `[text:show_close_mode_expand]`,
                        'text:close_style': `[text:close_style_expand]`,
                        'text:close_mode_second': '[text:close_mode_second_expand]',
                        'text:beautify_percent': '[text:beautify_percent_expand]',
                        'text:close_out_container': 'false',
                        autoShowButton: false,
                        TrackingHelper: self.TrackingHelper,
                        onExpandHelperClose: [
                            function () {
                                self.refs['PickPop'].onCollapse();
                            }
                        ],
                        onprepared: [
                            function (comp) {
                                if (self.refs['PickPop'].config.disableExpand) {
                                    comp.config.disableCta = true;
                                }
                            }
                        ]
                    },
                    demoOption: {
                        'text:show_close_mode': 'show',
                        'text:vertical_align_button_percentage': '0',
                        'text:horizontal_align_button_percentage': '100',
                    }
                }
            }
        ]
    });
});