<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import home from './views/Home.vue'
import showcase from './views/Showcase.vue'

export default {
  name: 'app',
}
</script>
