
function loadJs(url) {
    return new Promise((resolve, reject) => {
        const script = document.createElement("script")
        script.src = url
        script.onload = resolve
        script.onerror = reject
        document.body.appendChild(script)
    });
}
async function asyncLoadJs(para) {
    await loadJs('https://d2v4tz4zvhrua.cloudfront.net/production/dist/2021-07-19-1626682267011/common/TemplateBuilder.min.js').catch(err => { })
}
asyncLoadJs().then(() => {
  let template = new TemplateBuilder({
    cdn: 'https://d2v4tz4zvhrua.cloudfront.net/production/dist/2021-07-30-1627619983081',
    plugins: [],
    adformats: [
      'FSImpressive', 'FSInterstitial'
    ],
    components: [
      'SelectCard', 'SelectCardSide', 'ExpandHelper', 'NewCloseButton'
    ],
    develop: false,
    developRelativePath: '../../../..',
    Tracking: [
      function (self) {
        return {
          option: {
            'request_id': '[request_id]',
            wantedTrackingList: [
              'close', 'impression', 'video_percentage', 'video_progress', 'custom', {
                'view': '1'
              }, {
                'accept_invitation': 1
              }, {
                expand: '1'
              }, {
                collapse: '1'
              }
            ],
            fbPixelId: '748409338986541',
            wantedFbPixelList: [
              'close', 'impression', 'video_percentage', 'video_progress', 'custom', {
                'view': '1'
              }, {
                'accept_invitation': 1
              }, {
                expand: '1'
              }, {
                collapse: '1'
              }
            ],
          },
          demoOption: {
            'text:3rd_party_track_url': '',
          }
        }
      }
    ],
    AdBox: [
      function (self) {
        return {
          refName: 'AdBox',
          option: {
            boxStyle: 'impressive',
            plugins: [
              'https://man.vm5apis.com/dist/MixPlayer/1.4.0/MixPlayer.js',
            ]
          },
          demoOption: {
            'request_id': '55667788',
          }
        }
      },
      function (self) {
        return {
          refName: 'SideAdBox',
          option: {
            boxStyle: 'fixed',
            plugins: []
          },
          demoOption: {
            'request_id': '55667788-side',
          }
        }
      },
      function (self) {
        return {
          refName: 'ExpandAdBox',
          option: {
            boxStyle: 'fixed',
            plugins: [
              'https://man.vm5apis.com/dist/MixPlayer/1.4.0/MixPlayer.js'
            ]
          },
          demoOption: {
            'request_id': '55667788-expand',
          }
        }
      }
    ],
    BaseAdUnit: [
      function (self) {
        return {
          adformatName: 'FSImpressive',
          refName: 'FSImpressive',
          option: {
            el: self.refs['AdBox'].practicalAdContainer,
            AdBox: self.refs['AdBox'],
            TrackingHelper: self.TrackingHelper,
            'image:background_image': '',
            'video:video1:mp4': '[video:video1:mp4]',
            'image:cover': '[image:cover]',
            onprepared: [
              function (comp) {
                self.refs['impressiveCardInView'] = false;
                self.refs['sideClose'] = false;
              }
            ],
            onClickAvailableChanged: [
              function (comp) {
                if (!self.refs['impressiveCardInView']) {
                  self.refs['SelectCard'].showCardList();
                  self.refs['SelectCard'].config.isInView = true;
                  self.refs['impressiveCardInView'] = true;
                  if (!self.refs['sideClose']) {
                    self.refs['SelectCardSide'].hideWrap();
                    self.refs['SideNewCloseButton'].hideButton();
                  }
                } else {
                  self.refs['SelectCard'].hideCardList();
                  self.refs['SelectCard'].config.isInView = false;
                  self.refs['SelectCardSide'].showWrap();
                  self.refs['impressiveCardInView'] = false;
                  if (!self.refs['sideClose']) {
                    self.refs['SideNewCloseButton'].showButton();
                  }
                }
              }
            ],
          },
          demoOption: {
            'text:in_view_callback_rate': '20',
            'video:video1:mp4': 'https://d2v4tz4zvhrua.cloudfront.net/manual_uploads/20210728/video.mp4',
            'image:cover': 'https://d2v4tz4zvhrua.cloudfront.net/manual_uploads/20210728/cover.png',

            'cta:default': 'https://adison.com.tw/landing?utm_source=adison_ad_cta',
            'cta:video1': 'https://adison.com.tw/landing?utm_source=adison_ad_cta',

            'text:vertical_align_video_percentage': '',
            'text:is_fixed': false,
            videoScale: '16:9',
            'text:logo_vertical_position': '',
            'text:logo_horizontal_position': '',
            'text:click_available_vh_rate': '0.7',
          },
        };
      },
      function (self) {
        return {
          adformatName: 'FSInterstitial',
          refName: 'FSInterstitial',
          option: {
            el: self.refs['ExpandAdBox'].practicalAdContainer,
            TrackingHelper: self.TrackingHelper,
            AdBox: self.refs['ExpandAdBox'],
            disableImpression: true,
            'video:video1:mp4': '[video:video1:mp4]',
            'image:cover': '[image:cover]',
            'image:background_image': ``,
            'image:foreground_image': ``,
            onprepared: []
          },
          demoOption: {
            'cta:default': 'https://adison.com.tw/landing?utm_source=adison_ad_cta',
            'cta:video1': 'https://adison.com.tw/landing?utm_source=adison_ad_cta',
            'text:logo_vertical_position': 'bottom',
            'text:logo_horizontal_position': 'left',
            'video:video1:mp4': 'https://d2v4tz4zvhrua.cloudfront.net/manual_uploads/20210728/video.mp4',
            'image:cover': 'https://d2v4tz4zvhrua.cloudfront.net/manual_uploads/20210728/cover.png',
          }
        };
      }
    ],
    OnBaseAdUnitPrepared: [
      function (self) {
        return {
          componentName: 'SelectCardSide',
          refName: 'SelectCardSide',
          option: {
            el: self.refs['SideAdBox'].practicalAdContainer,
            TrackingHelper: self.TrackingHelper,
            AdBox: self.refs['SideAdBox'],
            cardListFlyIn: true,
            medias: '[' +
              '{"card": "[image:card1]", "ctaURL": "[cta:cta1]"},' +
              '{"card": "[image:card2]", "ctaURL": "[cta:cta2]"},' +
              '{"card": "[image:card3]", "ctaURL": "[cta:cta3]"},' +
              '{"card": "[image:card4]", "ctaURL": "[cta:cta4]"},' +
              '{}]',
            onprepared: [
              function (comp) {
                if (!self.refs['impressiveCardInView']) {
                  comp.showWrap();
                  self.refs['SideNewCloseButton'].showButton();
                }
              }
            ],
            onctaclicked: [
              function (comp) {
                if (self.refs['FSImpressive'].config.isPass) {
                  let sideCardCta = `https://adison.com.tw/landing?utm_source=adison_ad_cta`;
                  let cta = sideCardCta.length === 0 || sideCardCta === '' ? comp.config['cta:default'] :
                    sideCardCta;
                  window.top.location.href =
                    `${comp.config['dfp_url']}${comp.config['cta_prefix_url']}&url=${cta}`;
                } else {
                  self.refs['SelectCardExpand'].selectCard(null, comp.currentIndex);
                  self.refs['FSInterstitial'].mix.handler.player.play();
                  self.refs['FSInterstitial'].mix.handler.player.seekTo(self.refs['getSeekTime']());
                  self.refs['ExpandHelper'].expandedVisible('show');
                  self.refs['ExpandHelper'].originalVisible('hide');
                }
              }
            ]
          },
          demoOption: {
            'text:card_border_radius': `true`,
            // 'text:auto_play_period': `9999999999`,
            'cta:default': `https://adison.com.tw/landing?utm_source=adison_ad_cta`,
            medias: '[' +
              '{"card": "https://d2v4tz4zvhrua.cloudfront.net/manual_uploads/20210728/in_and_out/in_and_out_card_1.png", "ctaURL": "[cta:cta1]"},' +
              '{"card": "https://d2v4tz4zvhrua.cloudfront.net/manual_uploads/20210728/in_and_out/in_and_out_card_2.png", "ctaURL": "[cta:cta2]"},' +
              '{"card": "https://d2v4tz4zvhrua.cloudfront.net/manual_uploads/20210728/in_and_out/in_and_out_card_3.png", "ctaURL": "[cta:cta3]"},' +
              '{}]',
            // 'text:card_side': `right`,
          }
        }
      },
      function (self) {
        return {
          componentName: 'SelectCard',
          refName: 'SelectCard',
          option: {
            el: self.refs['FSImpressive'].adContainerView,
            TrackingHelper: self.TrackingHelper,
            AdBox: self.refs['AdBox'],
            cardListFlyIn: true,
            onprepared: [
              function (comp) {
                if (self.refs['impressiveCardInView']) {
                  comp.showCardList();
                } else {
                  comp.config.isInView = false;
                }
              }
            ],
            medias: '[' +
              '{"card": "[image:card1]", "background": "[image:background_1]", "ctaURL": "[cta:cta1]"},' +
              '{"card": "[image:card2]", "background": "[image:background_2]", "ctaURL": "[cta:cta2]"},' +
              '{"card": "[image:card3]", "background": "[image:background_3]", "ctaURL": "[cta:cta3]"},' +
              '{"card": "[image:card4]", "background": "[image:background_4]", "ctaURL": "[cta:cta4]"},' +
              '{}]',
          },
          demoOption: {
            'text:card_border_radius': `true`,
            'text:auto_play': `true`,
            medias: '[' +
              '{"card": "https://d2v4tz4zvhrua.cloudfront.net/manual_uploads/20210728/in_and_out/in_and_out_card_1.png", "background": "https://d2v4tz4zvhrua.cloudfront.net/manual_uploads/20210728/in_and_out/in_and_out_video_banner_1.png", "ctaURL": "https://adison.com.tw/landing?utm_source=adison_ad_cta"},' +
              '{"card": "https://d2v4tz4zvhrua.cloudfront.net/manual_uploads/20210728/in_and_out/in_and_out_card_2.png", "background": "https://d2v4tz4zvhrua.cloudfront.net/manual_uploads/20210728/in_and_out/in_and_out_video_banner_2.png", "ctaURL": "https://adison.com.tw/landing?utm_source=adison_ad_cta"},' +
              '{"card": "https://d2v4tz4zvhrua.cloudfront.net/manual_uploads/20210728/in_and_out/in_and_out_card_3.png", "background": "https://d2v4tz4zvhrua.cloudfront.net/manual_uploads/20210728/in_and_out/in_and_out_video_banner_3.png", "ctaURL": "https://adison.com.tw/landing?utm_source=adison_ad_cta"},' +
              '{}]',
          }
        }
      },
      function (self) {
        return {
          componentName: 'SelectCard',
          refName: 'SelectCardExpand',
          option: {
            el: self.refs['FSInterstitial'].adContainerView,
            TrackingHelper: self.TrackingHelper,
            AdBox: self.refs['ExpandAdBox'],
            cardListFlyIn: false,
            medias: '[' +
              '{"card": "[image:card1]", "background": "[image:background_1]", "ctaURL": "[cta:cta1]"},' +
              '{"card": "[image:card2]", "background": "[image:background_2]", "ctaURL": "[cta:cta2]"},' +
              '{"card": "[image:card3]", "background": "[image:background_3]", "ctaURL": "[cta:cta3]"},' +
              '{"card": "[image:card4]", "background": "[image:background_4]", "ctaURL": "[cta:cta4]"},' +
              '{}]',
          },
          demoOption: {
            'text:card_border_radius': `true`,
            'request_id': '55667788-expand',
            'text:auto_play': `true`,
            medias: '[' +
              '{"card": "https://d2v4tz4zvhrua.cloudfront.net/manual_uploads/20210728/in_and_out/in_and_out_card_1.png", "background": "https://d2v4tz4zvhrua.cloudfront.net/manual_uploads/20210728/in_and_out/in_and_out_video_banner_1.png", "ctaURL": "https://adison.com.tw/landing?utm_source=adison_ad_cta"},' +
              '{"card": "https://d2v4tz4zvhrua.cloudfront.net/manual_uploads/20210728/in_and_out/in_and_out_card_2.png", "background": "https://d2v4tz4zvhrua.cloudfront.net/manual_uploads/20210728/in_and_out/in_and_out_video_banner_2.png", "ctaURL": "https://adison.com.tw/landing?utm_source=adison_ad_cta"},' +
              '{"card": "https://d2v4tz4zvhrua.cloudfront.net/manual_uploads/20210728/in_and_out/in_and_out_card_3.png", "background": "https://d2v4tz4zvhrua.cloudfront.net/manual_uploads/20210728/in_and_out/in_and_out_video_banner_3.png", "ctaURL": "https://adison.com.tw/landing?utm_source=adison_ad_cta"},' +
              '{}]',
          }
        }
      },
      function (self) {
        return {
          componentName: 'ExpandHelper',
          refName: 'ExpandHelper',
          option: {
            original: self.refs['AdBox'].boxEl,
            expanded: self.refs['ExpandAdBox'].boxEl,
            expandedId: self.refs['ExpandAdBox'].config['request_id'],
            showExpandButton: false,
            TrackingHelper: self.TrackingHelper,
            onprepared: [
              function (comp) {
                comp.expandedVisible('hide');
                comp.originalVisible('show');
              }
            ],
            onShow: [
              function (comp) {
                self.refs['ExpandAdBox'].height = `${window.innerHeight}px`;
                self.refs['ExpandNewCloseButton'].showButton();
                self.refs['ExpandAdBox'].disableScroll();
              }
            ]
          },
          demoOption: {}
        }
      },
      function (self) {
        return {
          componentName: 'NewCloseButton',
          refName: 'SideNewCloseButton',
          option: {
            el: self.refs['SideAdBox'].boxWrapper,
            AdBox: self.refs['SideAdBox'],
            closeTarget: self.refs['SideAdBox'].boxWrapper,
            'request_id': '[request_id]-side',
            TrackingHelper: self.TrackingHelper,
            autoShowButton: false,
            onclosed: [
              function (comp) {
                self.refs['sideClose'] = true;
              }
            ],
            'text:close_out_container': 'false',
            'cta:custom_close': `[cta:custom_close_side]`,
            'text:vertical_align_button_percentage': '[text:vertical_align_button_percentage_side]',
            'text:horizontal_align_button_percentage': '[text:horizontal_align_button_percentage_side]',
            'text:show_close_mode': `[text:show_close_mode_side]`,
            'text:close_style': `[text:close_style_side]`,
            'text:close_mode_second': '[text:close_mode_second_side]',
            'text:beautify_percent': '[text:beautify_percent_side]',
          },
          demoOption: {
            'request_id': '55667788-side',
            'text:show_close_mode': 'fadein',
            'text:vertical_align_button_percentage': '0',
            'text:horizontal_align_button_percentage': '100',
            'text:close_style': `general`
          }
        }
      },
      function (self) {
        return {
          componentName: 'NewCloseButton',
          refName: 'ExpandNewCloseButton',
          option: {
            el: self.refs['FSInterstitial'].adContainerView,
            'request_id': '[request_id]-expand',
            TrackingHelper: self.TrackingHelper,
            onExpandHelperClose: [
              function (comp) {
                self.refs['ExpandAdBox'].enableScroll();
                self.refs['ExpandHelper'].expandedVisible('hide');
                self.refs['ExpandHelper'].originalVisible('show');
                self.refs['FSImpressive'].mix.handler.player.play();
                self.refs['FSImpressive'].mix.handler.player.seekTo(self.refs['getSeekTime']());
              }
            ],
            autoShowButton: false,
            'text:close_out_container': 'false',
            'cta:custom_close': `[cta:custom_close_expand]`,
            'text:vertical_align_button_percentage': '[text:vertical_align_button_percentage_expand]',
            'text:horizontal_align_button_percentage': '[text:horizontal_align_button_percentage_expand]',
            'text:show_close_mode': `[text:show_close_mode_expand]`,
            'text:close_style': `[text:close_style_expand]`,
            'text:close_mode_second': '[text:close_mode_second_expand]',
            'text:beautify_percent': '[text:beautify_percent_expand]',
          },
          demoOption: {
            'request_id': '55667788-expand',
            'text:show_close_mode': 'fadein',
            'text:vertical_align_button_percentage': '0',
            'text:horizontal_align_button_percentage': '100',
            'text:close_style': `general`
          }
        }
      },
      function (self) {
        self.refs['getSeekTime'] = function (position) {
          let impressiveSeek = self.refs['FSImpressive'].mix.handler.player.getCurrentPosition();
          let interstitialSeek = self.refs['FSInterstitial'].mix.handler.player.getCurrentPosition();
          let pos = Math.max(impressiveSeek, interstitialSeek);
          return pos;
        }
      }
    ]
  });
})