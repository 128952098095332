function loadJs(url) {
    return new Promise((resolve, reject) => {
        const script = document.createElement("script")
        script.src = url
        script.onload = resolve
        script.onerror = reject
        document.body.appendChild(script)
    });
}
async function asyncLoadJs(para) {
  await loadJs('https://d2v4tz4zvhrua.cloudfront.net/production/dist/2021-07-19-1626682267011/common/TemplateBuilder.min.js').catch(err => { })
}
asyncLoadJs().then(() => {
    let template = new TemplateBuilder({
        cdn: 'https://d2v4tz4zvhrua.cloudfront.net/production/dist/2021-12-21-1640057048277',
        plugins: [],
        adformats: [
          'BannerContainerBackground', 'FSInterstitial'
        ],
        components: [
          'NewCloseButton', 'ExpandHelper', 'unit/BVideo', 'Leading2', 'TickTock'
        ],
        develop: false,
        developRelativePath: '../../../..',
        Tracking: [
          function (self) {
            return {
              option: {
                el: '#vmfive-ad-unit-container-55667788',
                'request_id': '[request_id]',
                wantedTrackingList: [
                  'close', 'impression', 'video_percentage', 'video_progress', 'custom', 'view', {
                    'expand': 1
                  }, {
                    'collapse': 1
                  }, {
                    'accept_invitation': 1
                  }
                ],
                fbPixelId: '748409338986541',
                wantedFbPixelList: [
                  'close', 'impression', 'video_percentage', 'video_progress', 'custom', 'view', {
                    'expand': 1
                  }, {
                    'collapse': 1
                  }, {
                    'accept_invitation': 1
                  }
                ],
              },
              demoOption: {
                'text:3rd_party_track_url': ''
              }
            }
          }
        ],
        AdBox: [
          function (self) {
            return {
              refName: 'BaseAdBox',
              option: {
                boxStyle: 'fixed',
                plugins: []
              },
              demoOption: {
                'request_id': '55667788',
              }
            }
          },
          function (self) {
            return {
              refName: 'ExpandAdBox',
              option: {
                boxStyle: 'fixed',
                plugins: [
                  'https://man.vm5apis.com/dist/MixPlayer/1.4.4/MixPlayer.js',
                ]
              },
              demoOption: {
                'request_id': '55667788-expand',
              }
            }
          }
        ],
        BaseAdUnit: [
          function (self) {
            return {
              adformatName: 'BannerContainerBackground',
              refName: 'BannerContainerBackground',
              option: {
                el: self.refs['BaseAdBox'].practicalAdContainer,
                enableClipPath: false,
                disableCta: true,
                TrackingHelper: self.TrackingHelper,
                AdBox: self.refs['BaseAdBox'],
                'text:banner_ratio': '32:32',
                onctaclicked: [
                  function () {
                    self.refs['ExpandHelper'].expandedVisible('show');
                    self.refs['ExpandHelper'].originalVisible('hide');
                  }
                ]
              },
              demoOption: {
                'cta:default': 'https://adison.com.tw/landing?utm_source=adison_ad_cta',
                'image:container_background': 'https://d2v4tz4zvhrua.cloudfront.net/manual_uploads/20220114/Leading_320h_Expand/banner.gif',
              }
            };
          },
          function (self) {
            return {
              adformatName: 'FSInterstitial',
              refName: 'FSInterstitial',
              option: {
                el: self.refs['ExpandAdBox'].practicalAdContainer,
                TrackingHelper: self.TrackingHelper,
                AdBox: self.refs['ExpandAdBox'],
                disableImpression: true,
                hasVideo: true
              },
              demoOption: {
                'image:image_background': `https://d2v4tz4zvhrua.cloudfront.net/manual_uploads/20220114/Leading_320h_Expand/expand_bg.gif`,
                'image:image_foreground': ``,
                'text:video_ratio': '360:640',
                'text:video_scale_percentage': `78`,
                'text:video_vertical_align_percentage': '70',
                'text:video_horizontal_align_percentage': '50',
                'cta:default': `https://adison.com.tw/landing?utm_source=adison_ad_cta`,
              }
            };
          }
        ],
        OnBaseAdUnitPrepared: [
          function (self) {
            return {
              componentName: 'TickTock',
              refName: 'TickTock',
              option: {
                el: self.refs['BannerContainerBackground'].adContainerView,
                AdBox: self.refs['BaseAdBox'],
                zIndex: 2000000,
                'text:image_ratio': '1:1',
                'text:image_width_percentage': '100',
                'image:image_ticktock': 'https://d2v4tz4zvhrua.cloudfront.net/manual_uploads/20210406/transparent.png',
                'text:image_vertical_align_percentage': '100',
                'text:image_horizontal_align_percentage': '50',
                'text:no_day_mode': 'true',
                'text:day_horizontal_align_percentage': '0'
              },
              demoOption: {
                'text:end_time': '2021/12/22 00:00:00',
                'text:text_vertical_align_percentage': '86',
                'text:hour_horizontal_align_percentage': '29',
                'text:min_horizontal_align_percentage': '45',
                'text:sec_horizontal_align_percentage': '59.8',
                'text:font_size': '25',
                'text:font_color': '#8afffb',
              }
            }
          },
          function (self) {
            return {
              componentName: 'BVideo',
              refName: 'BVideo',
              option: {
                el: self.refs['FSInterstitial'].videoContainer,
                TrackingHelper: self.TrackingHelper,
                "text:videoLoop": 'true',
                AdBox: self.refs['ExpandAdBox'],
                disableAutoplay: false,
                onprepared: [
                  function (comp) {
                    self.refs['FSInterstitial'].videoController.set(comp.controller);
                  }
                ]
              },
              demoOption: {
                "video:video1:mp4": 'https://d2v4tz4zvhrua.cloudfront.net/manual_uploads/20220114/Leading_320h_Expand/video.mp4',
                'image:cover': '',
                'text:video_ratio': '360:640',
                'cta:video': `https://adison.com.tw/landing?utm_source=adison_ad_cta`
              }
            }
          },
          function (self) {
            return {
              componentName: 'ExpandHelper',
              refName: 'ExpandHelper',
              option: {
                original: self.refs['BaseAdBox'].boxEl,
                expanded: self.refs['ExpandAdBox'].boxEl,
                expandedId: self.refs['ExpandAdBox'].config['request_id'],
                showExpandButton: false,
                TrackingHelper: self.TrackingHelper,
                onprepared: [
                  function (comp) {
                    comp.expandedVisible('hide');
                    comp.originalVisible('show');
                  }
                ],
                onShow: [
                  function (comp) {
                    self.refs['ExpandAdBox'].disableScroll();
                    self.refs['ExpandAdBox'].height = window.innerHeight + 'px';
                    self.refs['FSInterstitial'].resetVideoPosition();
                    self.refs['ExpandNewCloseButton'].showButton();
                    self.refs['NewCloseButton'].hideButton();
                  }
                ]
              }
            }
          },
          function (self) {
            return {
              componentName: 'NewCloseButton',
              refName: 'NewCloseButton',
              option: {
                el: self.refs['BaseAdBox'].boxWrapper,
                AdBox: self.refs['BaseAdBox'],
                closeTarget: [self.refs['BaseAdBox'].boxWrapper, self.refs['ExpandAdBox'].boxWrapper],
                TrackingHelper: self.TrackingHelper,
                unique_name: 'default',
                autoShowButton: false,
              },
              demoOption: {
                'text:show_close_mode': 'fadein',
                'text:vertical_align_button_percentage': '0',
                'text:horizontal_align_button_percentage': '0',
                'text:close_out_container': 'false',
              }
            }
          },
          function (self) {
            return {
              componentName: 'NewCloseButton',
              refName: 'ExpandNewCloseButton',
              option: {
                el: self.refs['ExpandAdBox'].boxWrapper,
                'request_id': '[request_id]-expand',
                TrackingHelper: self.TrackingHelper,
                onExpandHelperClose: [
                  function (comp) {
                    self.refs['ExpandAdBox'].enableScroll();
                    self.refs['ExpandHelper'].expandedVisible('hide');
                    self.refs['ExpandHelper'].originalVisible('show');
                    self.refs['NewCloseButton'].showButton();
                  }
                ],
                autoShowButton: false,
                'text:close_out_container': 'false',
                'cta:custom_close': `[cta:custom_close_expand]`,
                'text:vertical_align_button_percentage': '[text:vertical_align_button_percentage_expand]',
                'text:horizontal_align_button_percentage': '[text:horizontal_align_button_percentage_expand]',
                'text:show_close_mode': `[text:show_close_mode_expand]`,
                'text:close_style': `[text:close_style_expand]`,
                'text:close_mode_second': '[text:close_mode_second_expand]',
                'text:beautify_percent': '[text:beautify_percent_expand]',
              },
              demoOption: {
                'request_id': '55667788-expand',
                'text:vertical_align_button_percentage': '0',
                'text:horizontal_align_button_percentage': '0',
              }
            }
          },
          function (self) {
            return {
              componentName: 'Leading2',
              refName: 'Leading2',
              option: {
                containerOverlayRoot: self.refs['BaseAdBox'].practicalView,
                containerInsertRoot: self.refs['BaseAdBox'].phantomView,
                AdBox: self.refs['BaseAdBox'],
                zIndex: self.refs['BannerContainerBackground'].config.bannerZIndex,
                'text:iframe_url': '[text:iframe_url]',
                PreloadHelper: self.PreloadHelper,
                oncomplete: [
                  function (com) {
                    self.refs['NewCloseButton'].showButton();
                  }
                ],
              },
              demoOption: {
                'text:iframe_name': 'martx_leading',
                'text:iframe_url': 'https://d2v4tz4zvhrua.cloudfront.net/leading_material/20211130_mrtx_leading/mtrx_leading.html'
              }
            }
          },
        ]
      });
});