function loadJs(url) {
    return new Promise((resolve, reject) => {
        const script = document.createElement("script")
        script.src = url
        script.onload = resolve
        script.onerror = reject
        document.body.appendChild(script)
    });
}
async function asyncLoadJs(para) {
    await loadJs('https://cdnjs.cloudflare.com/ajax/libs/jquery/2.1.3/jquery.min.js').catch(err => { })
    await loadJs('https://man.vm5apis.com/dist/sk-vmfive.js/sk-vmfive.js').catch(err => { })
    await loadJs('https://man.vm5apis.com/dist/raf.js').catch(err => { })
    await loadJs('https://man.vm5apis.com/dist/anime.js/3.0.1/anime.min.js').catch(err => { })
    await loadJs('https://d2v4tz4zvhrua.cloudfront.net/production/dist/2020-02-24-1582531073384/common/TemplateBuilder.min.js').catch(err => { })
}
asyncLoadJs().then(() => {
    let template = new TemplateBuilder({
        cdn: 'https://d2v4tz4zvhrua.cloudfront.net/production/dist/2020-02-24-1582531073384',
        plugins: [
        ],
        adformats: [
            'BannerContainer',
        ],
        components: [
            'Slot', 'CloseButton', 'Flyout'
        ],
        develop: false,
        Tracking: [
            function (self) {
                return {
                    option: {
                        el: '#vmfive-ad-unit-container-55667788',
                        'request_id': '[request_id]',
                        wantedTrackingList: [
                            'impression', 'close', 'custom'
                        ],
                        fbPixelId: '',
                    },
                    demoOption: {
                        'text:3rd_party_track_url': '',
                    }
                }
            }
        ],
        BaseAdUnit: [
            function (self) {
                return {
                    adformatName: 'BannerContainer',
                    refName: 'BannerContainer',
                    option: {
                        el: '#vmfive-ad-unit-container-55667788',
                        'request_id': '[request_id]',
                        'text:banner_ratio': '32:10',
                        enableClipPath: false,
                        'text:logo_horizontal_alignment': 'left',
                        TrackingHelper: self.TrackingHelper,
                    },
                    demoOption: {
                        'cta:default': 'https://adison.com.tw/landing/',
                    }
                };
            },
            function (self) {
                let openningEl = document.createElement('div');
                openningEl.classList.add('openning');
                openningEl.style.position = 'absolute';
                openningEl.style.left = '0';
                openningEl.style.bottom = '0';
                openningEl.style.width = '100%';
                openningEl.style.zIndex = 1;

                let openningImg = document.createElement('img');
                openningImg.src = 'https://ad-demo.vmfive.com/demos/showcase/20200227_mothersday/flout_slot/resources/luj_flyout_opening.png';
                openningImg.style.position = 'absolute';
                openningImg.style.left = 0;
                openningImg.style.bottom = 0;
                openningImg.style.width = '100%';
                openningEl.appendChild(openningImg);

                self.refs['BannerContainer'].adContainerView.appendChild(openningEl);
            }
        ],
        OnBaseAdUnitPrepared: [
            function (self) {
                return {
                    componentName: 'CloseButton',
                    refName: 'CloseButton',
                    option: {
                        el: self.refs['BannerContainer'].adContainerView,
                        'request_id': '[request_id]',
                        TrackingHelper: self.TrackingHelper,
                    },
                    demoOption: {
                        'text:cta_in_new_window': `[text:cta_in_new_window]`,
                        'dfp_url': `[dfp_url]`,
                        'cta_prefix_url': `[cta_prefix_url]`,
                        'cta:default': `https://adison.com.tw/landing/`,

                        'text:embedded': '[text:embedded]',
                        'text:show_close_mode': 'fadein',
                        'text:close_button_horizontal_alignment': 'right',
                    }
                }
            },
            function (self) {
                return {
                    componentName: 'Flyout',
                    refName: 'Flyout',
                    option: {
                        el: self.refs['BannerContainer'].adContainerView,
                        'request_id': '[request_id]',
                        'image:banner': '',
                        'image:leader': 'https://ad-demo.vmfive.com/demos/showcase/20200227_mothersday/flout_slot/resources/luj_flyout_drop.png',
                        'text:move_duration': `3000`,
                        'text:banner_showup_delay': `0`,

                        'text:rise_or_drop': `drop`,
                        'text:horizontal_density_level': `7`,
                        'text:vertical_density_level': ``,
                        'text:fly_over_banner': ``,
                        'text:mass_vertical_elements': 'false',
                        'text:mass_horizontal_elements': 'false',

                        'text:shake_elements': `true`,
                        'text:shake_level': `1`,

                        onflyoutstart: [
                            function (component) {
                                self.refs['BannerContainer'].rootView.querySelector('.openning');
                                anime({
                                    targets: self.refs['BannerContainer'].rootView.querySelector('.openning'),
                                    opacity: [1, 0],
                                    bottom: ['0', '-100%'],
                                    delay: (self.refs['Flyout'].config.startDelay + self.refs['Flyout'].config.moveDuration * self.refs['Flyout'].bannerEnterDelayCoef) * 1 + 250,
                                    easing: 'easeInOutSine',
                                    complete: function () {
                                        anime({
                                            targets: self.refs['Slot'].slotWrapper,
                                            opacity: [0, 1],
                                            delay: 150,
                                            easing: 'easeInOutSine',
                                            complete: function () {
                                                self.refs['Slot'].playInterval();
                                            }
                                        });
                                    }
                                });
                            },
                        ],

                        onctaclicked: [
                            function (component, extra) {
                                self.refs['BannerContainer'].onCtaClicked(extra.e);
                            },
                        ],
                    }
                }
            },
            function (self) {
                return {
                    componentName: 'Slot',
                    refName: 'Slot',
                    option: {
                        el: self.refs['BannerContainer'].adContainerView,
                        disableAutoStartMove: true,
                    },
                    demoOption: {
                        'text:banner_ratio': '32:10',
                        'image:background': 'https://ad-demo.vmfive.com/demos/showcase/20200227_mothersday/flout_slot/resources/luj_slot_bg.png',
                        media:
                            '[' +
                            '{"image": "https://ad-demo.vmfive.com/demos/showcase/20200227_mothersday/flout_slot/resources/luj_slot_banner_01.gif"},' +
                            '{"image": "https://ad-demo.vmfive.com/demos/showcase/20200227_mothersday/flout_slot/resources/luj_slot_banner_02.gif"},' +
                            '{"image": "https://ad-demo.vmfive.com/demos/showcase/20200227_mothersday/flout_slot/resources/luj_slot_banner_03.png"},' +
                            '{}]',
                    }
                }
            },
            function (self) {
                self.refs['Slot'].slotWrapper.style.opacity = 0;
            },
        ]
    });
})