function loadJs(url) {
  return new Promise((resolve, reject) => {
    const script = document.createElement("script")
    script.src = url
    script.onload = resolve
    script.onerror = reject
    document.body.appendChild(script)
  });
}
async function asyncLoadJs(para) {
  await loadJs('https://d2v4tz4zvhrua.cloudfront.net/production/dist/2021-07-19-1626682267011/common/TemplateBuilder.min.js').catch(err => {})
}
asyncLoadJs().then(() => {
  let template = new TemplateBuilder({
    cdn: 'https://d2v4tz4zvhrua.cloudfront.net/production/dist/2021-05-25-1621929552094',
    plugins: [],
    adformats: [
      'BannerContainerBackground'
    ],
    components: [
      'Leading2', 'DynamicWeather', 'NewCloseButton'
    ],
    develop: false,
    developRelativePath: '../../../..',
    Tracking: [
      function (self) {
        return {
          option: {
            el: '#vmfive-ad-unit-container-55667788',
            'request_id': '[request_id]',
            wantedTrackingList: [
              'impression', 'close', 'custom'
            ],
            fbPixelId: '748409338986541',
            wantedFbPixelList: [
              'impression', 'close', 'custom'
            ]
          },
          demoOption: {
            'text:3rd_party_track_url': '',
          }
        }
      }
    ],
    AdBox: [
      function (self) {
        return {
          refName: 'AdBox',
          option: {
            boxStyle: 'fixed',
            flexibleHeightRate: 1,
            plugins: [
              'https://man.vm5apis.com/dist/odometer.js/0.4.8/odometer.min.js'
            ]
          },
          demoOption: {
            'request_id': '55667788',
          }
        }
      }
    ],
    BaseAdUnit: [
      function (self) {
        return {
          adformatName: 'BannerContainerBackground',
          refName: 'BannerContainerBackground',
          option: {
            el: self.refs['AdBox'].practicalAdContainer,
            "text:video_is_rwd": '',
            enableClipPath: false,
            TrackingHelper: self.TrackingHelper,
            disableCta: false,
            onctaclicked: [],
            AdBox: self.refs['AdBox'],
            'text:banner_ratio': '32:10',
          },
          demoOption: {
            'image:container_background': 'https://ad-demo.vmfive.com/demos/showcase/20210809_dynamic_info/leading-pm25/container_background.gif',
            'cta:default': 'https://adison.com.tw/landing/?utm_source=adison_ad_cta',
          }
        };
      }
    ],
    OnBaseAdUnitPrepared: [
      function (self) {
        return {
          componentName: 'Leading2',
          refName: 'Leading2',
          option: {
            containerOverlayRoot: self.refs['AdBox'].practicalView,
            containerInsertRoot: self.refs['AdBox'].phantomView,
            AdBox: self.refs['AdBox'],
            zIndex: self.refs['BannerContainerBackground'].config.bannerZIndex,
            oncomplete: [
              function (com) {
                self.refs['NewCloseButton'].showButton();
              }
            ],
          },
          demoOption: {
            'text:iframe_name': 'outside',
            'text:iframe_url': 'https://d2v4tz4zvhrua.cloudfront.net/leading_material/20210324_strepsils_outside/strepsils_outside_leading.html'
          }
        }
      },
      function (self) {
        return {
          componentName: 'DynamicWeather',
          refName: 'DynamicWeather',
          option: {
            el: self.refs['BannerContainerBackground'].adContainerView,
            enableUVI: false,
            enableAQI: false,
            enablePM2Dot5: true,
            enableTemperature: false,
            enableRainfall: false,
            onprepared: [],
            AdBox: self.refs['AdBox'],
          },
          demoOption: {
            'text:dynamic_weather_title': '',
            'text:dynamic_weather_text_right': '',
            'text:bottom_percentage': '9',
            'text:left_percentage': '2',
            'text:weather_background_mask': 'rgba(255,247,213,2)',
          }
        }
      },
      function (self) {
        return {
          componentName: 'NewCloseButton',
          refName: 'NewCloseButton',
          option: {
            el: self.refs['AdBox'].boxWrapper,
            AdBox: self.refs['AdBox'],
            closeTarget: self.refs['AdBox'].boxWrapper,
            TrackingHelper: self.TrackingHelper,
            'request_id': '55667788',
            autoShowButton: false,
            unique_name: 'default'
          },
          demoOption: {
            'text:show_close_mode': '',
            'text:close_out_container': 'true',
          }
        }
      },
    ]
  });
})