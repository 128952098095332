
function loadJs(url) {
    return new Promise((resolve, reject) => {
        const script = document.createElement("script")
        script.src = url
        script.onload = resolve
        script.onerror = reject
        document.body.appendChild(script)
    });
}
async function asyncLoadJs(para) {
    await loadJs('https://d2v4tz4zvhrua.cloudfront.net/production/dist/2021-07-19-1626682267011/common/TemplateBuilder.min.js').catch(err => { })
}
asyncLoadJs().then(() => {
  let template = new TemplateBuilder({
    cdn: 'https://d2v4tz4zvhrua.cloudfront.net/production/dist/2021-07-30-1627619983081',
    plugins: [],
    adformats: [
      'FSImpressive', 'FSInterstitial'
    ],
    components: [
      'BumpUp', 'ExpandHelper', 'NewCloseButton'
    ],
    develop: false,
    developRelativePath: '../../../..',
    Tracking: [
      function (self) {
        return {
          option: {
            'request_id': '[request_id]',
            wantedTrackingList: [
              'impression', 'close', 'custom', {
                expand: '1'
              }, {
                collapse: '1'
              }
            ],
            fbPixelId: '748409338986541',
            wantedFbPixelList: [
              'impression', 'close', 'custom', {
                expand: '1'
              }, {
                collapse: '1'
              }
            ],
          },
          demoOption: {
            'text:3rd_party_track_url': '',
          }
        }
      }
    ],
    AdBox: [
      function (self) {
        return {
          refName: 'AdBox',
          option: {
            boxStyle: 'impressive',
            plugins: [
              'https://man.vm5apis.com/dist/MixPlayer/1.4.0/MixPlayer.js',
            ]
          },
          demoOption: {
            'request_id': '55667788',
          }
        }
      },
      function (self) {
        return {
          refName: 'ExpandAdBox',
          option: {
            boxStyle: 'fixed',
            plugins: [
              'https://man.vm5apis.com/dist/MixPlayer/1.4.0/MixPlayer.js',
              'https://cdnjs.cloudflare.com/ajax/libs/jquery/2.1.3/jquery.min.js',
              'https://man.vm5apis.com/dist/hammer.js/2.0.8/hammer.min.js',
              'https://man.vm5apis.com/dist/jquery.hammerjs/2.0.0/jquery.hammer.js',
            ]
          },
          demoOption: {
            'request_id': '55667788-expand',
          }
        }
      }
    ],
    BaseAdUnit: [
      function (self) {
        return {
          adformatName: 'FSImpressive',
          refName: 'FSImpressive',
          option: {
            el: self.refs['AdBox'].practicalAdContainer,
            AdBox: self.refs['AdBox'],
            TrackingHelper: self.TrackingHelper,
            onPass: [
              function (comp) {
                self.refs['ExpandHelper'].expandedVisible('show');
                comp.adContainerOuterView.style.visibility = 'hidden';
                self.refs['ExpandAdBox'].disableScroll();
              }
            ],
            'image:background_image': '[image:bump_front_image]',
            'video:video1:mp4': '',
            'image:cover': '',
          },
          demoOption: {
            'video:video1:mp4': '',
            'image:cover': '',
            'text:logo_vertical_position': '',
            'text:logo_horizontal_position': '',
            'image:background_image': 'https://d2v4tz4zvhrua.cloudfront.net/manual_uploads/20210728/bump/bump.png',
            'cta:default': 'https://adison.com.tw/landing?utm_source=adison_ad_cta',
            'cta:video1': 'https://adison.com.tw/landing?utm_source=adison_ad_cta',
          },
        };
      },
      function (self) {
        return {
          adformatName: 'FSInterstitial',
          refName: 'FSInterstitial',
          option: {
            el: self.refs['ExpandAdBox'].practicalAdContainer,
            TrackingHelper: self.TrackingHelper,
            AdBox: self.refs['ExpandAdBox'],
            disableImpression: true,
            'image:background_image': ``,
            'image:foreground_image': ``,
            'video:video1:mp4': '',
            'image:cover': '',
            onprepared: []
          },
          demoOption: {
            'cta:default': 'https://adison.com.tw/landing?utm_source=adison_ad_cta',
            'cta:video1': 'https://adison.com.tw/landing?utm_source=adison_ad_cta',
            'text:logo_vertical_position': 'bottom',
            'text:logo_horizontal_position': 'left',
            'video:video1:mp4': '',
            'image:cover': '',
          }
        };
      }
    ],
    OnBaseAdUnitPrepared: [
      function (self) {
        return {
          componentName: 'BumpUp',
          refName: 'BumpUp',
          option: {
            el: self.refs['FSInterstitial'].adContainerView,
            disableCta: false,
            TrackingHelper: self.TrackingHelper,
            AdBox: self.refs['ExpandAdBox'],
            'image:bump_front_image': '[image:bump_front_image]',
            onBump: []
          },
          demoOption: {
            'cta:front_cta': 'https://adison.com.tw/landing?utm_source=adison_ad_cta',
            'cta:back_cta': 'https://adison.com.tw/landing?utm_source=adison_ad_cta',
            'image:bump_front_image': 'https://d2v4tz4zvhrua.cloudfront.net/manual_uploads/20210728/bump/bump.png',
            'image:bump_back_image': 'https://d2v4tz4zvhrua.cloudfront.net/manual_uploads/20210728/bump/bump_expand.png',
          }
        }
      },
      function (self) {
        return {
          componentName: 'NewCloseButton',
          refName: 'ExpandNewCloseButton',
          option: {
            el: self.refs['ExpandAdBox'].boxWrapper,
            AdBox: self.refs['ExpandAdBox'],
            TrackingHelper: self.TrackingHelper,
            unique_name: 'expand',
            autoShowButton: false,
            onExpandHelperClose: [
              function (comp) {
                self.refs['ExpandAdBox'].enableScroll();
                self.refs['ExpandHelper'].expandedVisible('hide');
                self.refs['FSImpressive'].adContainerOuterView.style.visibility = 'visible';
              }
            ]
          },
          demoOption: {
            'request_id': '55667788-expand',
            'text:show_close_mode': '',
            'text:vertical_align_button_percentage': '0',
            'text:horizontal_align_button_percentage': '100',
            'text:close_out_container': '',
            'text:close_style': `general`
          }
        }
      },
      function (self) {
        return {
          componentName: 'ExpandHelper',
          refName: 'ExpandHelper',
          option: {
            original: self.refs['AdBox'].boxEl,
            expanded: self.refs['ExpandAdBox'].boxEl,
            expandedId: self.refs['ExpandAdBox'].config['request_id'],
            showExpandButton: false,
            TrackingHelper: self.TrackingHelper,
            onprepared: [
              function (comp) {
                comp.expandedVisible('hide');
                comp.originalVisible('show');
              }
            ],
            onShow: [
              function (comp) {
                self.refs['ExpandAdBox'].height = `${window.innerHeight}px`;
                self.refs['ExpandNewCloseButton'].showButton();
                let delay = function (s) {
                  return new Promise(function (resolve, reject) {
                    setTimeout(resolve, s);
                  });
                };
                delay().then(function () {
                  self.refs['FSImpressive'].config.onPass = [];
                  self.refs['BumpUp'].triggerBump();
                  self.refs['FSImpressive'].bgContainer.style.backgroundImage = `url(${self.refs['BumpUp'].config['image:bump_back_image']})`;
                  return delay(1000);
                }).then(function () {
                  self.refs['FSImpressive'].adContainerOuterView.style.visibility = 'visible';
                  comp.expandedVisible('hide');
                  self.refs['ExpandAdBox'].enableScroll();
                });
              }
            ]
          },
          demoOption: {
            onShow: [
              function (comp) {
                self.refs['ExpandAdBox'].height = `${window.innerHeight}px`;
                self.refs['ExpandNewCloseButton'].showButton();
                let delay = function (s) {
                  return new Promise(function (resolve, reject) {
                    setTimeout(resolve, s);
                  });
                };
                delay().then(function () {
                  self.refs['FSImpressive'].config.onPass = [];
                  self.refs['BumpUp'].triggerBump();
                  self.refs['FSImpressive'].bgContainer.style.backgroundImage = `url(https://d2v4tz4zvhrua.cloudfront.net/manual_uploads/20210728/bump/bump_expand.png)`;
                  return delay(1000);
                }).then(function () {
                  self.refs['FSImpressive'].adContainerOuterView.style.visibility = 'visible';
                  comp.expandedVisible('hide');
                  self.refs['ExpandAdBox'].enableScroll();
                });
              }
            ]
          }
        }
      }
    ]
  });
})