<template>
    <div>
        <header id="main-header">
            <div class="container clearfix header">
                <div class="logoContainer">
                    <span class="logoHelper"></span>
                    <a href="https://adison.com.tw/">
                        <div id="logo" alt="ADisON"></div>
                    </a>
                </div>
            </div>
        </header>
        <div class="page-container">
            <div class="content-wrapper">
                <div v-if=" adunitposition ==='top' " id="vmfive-ad-unit-container-55667788"></div>
                <img v-if=" adunitposition ==='top' " src="https://ad-demo.vmfive.com/" style="display:none;">
                <h2>ADisON透過原創廣告格式開創廣告新視界</h2>
                <h4>經濟日報 蔡尚勳</h4>

                <p>現今破碎化的廣告接觸行為導致半數以上數位廣告宣告無效， Google廣告禁令又將於今年7月上路，新創公司VMFive集團ADisON廣告事業部因此針對目前的數位廣告挑戰舉辦「讓創意攜手科技TURN
                    ON廣告新視界」活動，宣告全新的敏捷式廣告中心（Agile Advertising Center，簡稱AAC）正式上線，並推出4款最新原創廣告格式，除了可投放在PMP與Audience
                    Network上，同時相容於Google DV 360平台，在透過獨家動態數據模組與VPAID影音模組協助收集分析數據資料，以一站式數據迴圈系統提供更大的策略優化空間，落實廣告創意無限擴張。</p>
                <p>
                    VMFive集團ADisON廣告事業部總經理廖勝富表示：「協助廣告創意進行包裝是ADisON的廣告科技優勢與成立至今的核心發展之一，我們用技術串連協助廣告主、廣告代理商、媒體、數據夥伴與第三方合作單位所面臨的問題，在以使用者體驗為出發點的Applause原生廣告系統搭配ADisON
                    HTML5技術下已開發出42種原創廣告格式，具備138種變化形式，成功獲得超過200個品牌採用與12家媒體指定合作SaaS系統；Q1我們開發出多款符合Google廣告禁令規範的創意廣告格式，活用了既有版位又能吸引目光，其中的旋轉積木廣告格式（Spin
                    Cubic Ads）就讓點擊率提升了250%。」</p>
                <p>
                    此次活動在VMFive集團創辦人暨執行長丁俊宏的開場下揭開序幕，與會的嘉賓Medialand米蘭營銷共同創辦人暨副董事長陳琦琦會中表示：「科技讓各個平台與媒體發揮所長、各司其職互相合作方能創造更廣闊深刻廣告體驗。」Publicis陽獅集團媒體整合企劃副總經理
                    陳心慧則指出：「單一創意是無法滿足消費者的，利用動態創意優化技術讓數據和創意匯集在一起，為消費者創造出個人化的廣告體驗正是提高參與度和轉化率的關鍵。」LnData麟數據策略長謝佩芳提出：「創意可以藉由數據即時回饋抓住消費者的心，並實現品牌主第一方數據庫結合第二方與第三方數據庫來全面掌控自有數據資產的終極目標。」
                </p>

                <div v-if=" adunitposition ==='general' " id="vmfive-ad-unit-container-55667788"></div>
                <div v-if=" adunitposition ==='general' " id="vmfive-ad-unit-container-55667788-expand"></div>
                <div v-if=" adunitposition ==='general' " id="vmfive-ad-unit-container-55667788-pushdown"></div>
                <div v-if=" adunitposition ==='general' " id="vmfive-ad-unit-container-55667788-side"></div>
                <img v-if=" adunitposition ==='general' " src="https://ad-demo.vmfive.com/" style="display:none;">

                <h3>業界最多原創廣告格式 讓創意深入人心大獲品牌與媒體青睞</h3>
                <p>目前有眾多的數位廣告在一昧追求載入速度下犧牲了廣告素材的展示品質，ADisON為提供高品質的廣告內容，自2014年開始使用Google Cloud
                    Platform雲端服務平台，並將VMFive的AdPlay極致串流技術轉化為先進的雲端遞送技術；同時，以提高使用者體驗為出發點的Applause原生廣告系統搭配ADisON
                    HTML5技術開發出42種原創廣告格式，具備138種變化形式，以卓越成效兼具滿足使用者體驗。</p>
                <p>ADisON全新上線的敏捷式廣告中心（Agile Advertising
                    Center，簡稱AAC）能夠依據廣告的創意與客製化需求，透過工程團隊進行微型開發模組化庫，可快速組合進行輸出投放，並依據動態數據模組進行即時廣告監測。新推出的4款原創廣告格式分別為屬於動態效果模組的從天而降（Flyout
                    Ads）與俄羅斯方塊（Tetris Ads）廣告格式，透過不同的動態展示效果來吸引消費者眼球；屬於指尖互動模組的步進式廣告格式（Zoom
                    Ads）可讓使用者與廣告產生互動進而提升關注度；而屬於展開模組的打開眼界廣告格式（Click-to-Expand Ads ），則將會是在Google廣告禁令下取代蓋版廣告的絕佳選擇。</p>

                <h3>原創廣告格式相容Google DV360平台 數據應用更豐富觸及更廣</h3>
                <p>不少的品牌主使用單一渠道進行廣告投放，但渠道單一化勢必將面臨成本上升、彈性下降問題，ADisON集合超過300家精選優質媒體、社群與論壇的渠道流量，以及獨家5R
                    Engine技術搭配28個精準受眾群組，讓品牌有更多渠道與更準確策略的投放規劃；也因為渠道單一化，廣告格式有限，選擇性低，因此ADisOn自2019年Q2起開始採用Google DV
                    360平台作為渠道之一，目的在為使用單一渠道的品牌提供相容於單一渠道的技術。</p>
                <p>經ADisON整合過的Google DV 360平台具備以下特色：一除了自家Google
                    Ads的流量外，還可以採購其他AdExchange的流量，達到更即時更廣的觸及率；二是支援第三方受眾導入，因而讓數據應用更彈性；三是其內建多個特別的格式，讓廣告的呈現別具特色；更重要的是，ADisON大部分的原創格式相容於Google
                    DV 360平台的渠道，提高了客戶在廣告格式上面的選擇性，並兼顧滿足品牌媒體廣告活動觸及率最大化的需求。</p>

                <h3>與LnData合作推出一站式數據迴圈系統 品牌自擁透明數據不是夢</h3>
                <p>品牌目前還面臨在外部數據過度保護下，導致數據缺乏可信度與透明度的挑戰，ADisON針對此狀況以透過動態數據模組與VPAID影音廣告模組的解決方案，打造出高度相容第三方的廣告系統，除了可以支援Google
                    DCM，LnData TRACK，ComScore vce</p>
                <p>與Nielsen
                    DAR外，同時支援MOAT的監測。此外，由於各家供應商的格式與做法不同，數據破碎難以整合，讓數據看起來很多實際上能使用的卻很少，因此自2019年起，ADisON與LnData展開跨界一站式數據迴圈系統合作。
                </p>
                <p>所謂的一站式數據迴圈系統第一步是透過ADisON
                    AAC數據模組在各廣告階段彈性埋設、引用與收集各種數據資料，然後在投放階段參考使用LnData的DMP，曝光階段使用LnData的TRACK監測，最後在轉換與行動上使用SITE分析，而這些數據最終會在整合回LnData的DMP再次利用，更重要的是這些數據資料不再被外部渠道所把持，讓客戶可以擁有專屬於自己、綜合多方的數據資料。
                </p>
                <p>
                    VMFive集團ADisON廣告事業部總經理廖勝富最後表示：「LnData是業界肯定的數據專家，能提供完整的數據監控產品組合，ADisON與LnData的一站式數據迴圈策略合作，初期導入在汽車客戶的廣告上就產生非常好的成效，最終點擊率提升232%，並且讓觀看率提升354%。ADisON主張廣告創意的優化，必須在格式、渠道與數據三面向下足功夫，才能在龐大的數位廣告市場以及當前挑戰中脫穎而出。」
                </p>

                <h6 class="remark">
                    <b>【關於ADisON】</b>
                    <p>
                        ADisON是由母公司VMFive創立，獲得眾多客戶一致肯定技術領先的影音廣告科技品牌，具備自我突破與勇於創新的領航者精神，對於科技結合創意的熱情一刻不曾停歇。ADisON為廣告主、代理商及媒體合作夥伴提供全方位數位影音廣告服務，不斷創新的吸睛創意廣告格式和獨家先進5R智能化機器學習演算法，結合第三方數據在地公正支援與監測，成功有效協助品牌透過廣告與消費者建立聯結，點亮每一個品牌的動人故事。
                    </p>
                    <p>欲對ADisON有更進一步了解，請查詢官方網址：<a href="https://adison.com.tw/" target="_blank">https://adison.com.tw/</a>
                    </p>
                </h6>

                <hr />

                <p>文章來源：<a href="https://money.udn.com/money/story/10860/3751172">經濟日報</a></p>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'showcase',
  components: {
  },
  data() {
    return {
        adunitposition: 'general',
        publicPath: process.env.BASE_URL
    }
  },
  mounted() {
    //  For <header>
    //  form Old pool transplant
    let headerEl = document.querySelector(".header");
    let logoContainerEl = document.querySelector(".logoContainer");
    let logoEl = document.querySelector("#logo");
    let lastPageYOffset = 0;
    addEventListener('scroll', function () {
        if (lastPageYOffset <= 50 && pageYOffset > 50) {
            headerEl.classList.remove('higher'); headerEl.classList.add('shorter');
            logoContainerEl.classList.remove('higher'); logoContainerEl.classList.add('shorter');
            logoEl.classList.remove('bigger'); logoEl.classList.add('smaller');
        } else if (lastPageYOffset > 50 && pageYOffset <= 50) {
            headerEl.classList.remove('shorter'); headerEl.classList.add('higher');
            logoContainerEl.classList.remove('shorter'); logoContainerEl.classList.add('higher');
            logoEl.classList.remove('smaller'); logoEl.classList.add('bigger');
        }
        lastPageYOffset = pageYOffset;
    });


    require(`../../public/templates/${this.$route.params.templateClass}/${this.$route.params.templateName}.js`)
    this.adunitposition = this.$route.query.position === 'top' ? 'top' : 'general';
  },
  watch: {
  },
  computed: {
  },
  methods: {
  }
}
</script>

<style scoped lang="css">
@import '../../public/css/showcase.css';
</style>
