<template>
    <div>
        <div class="page-404">
          <div class="kv_warp">
            <img class="main_kv" src="img/404_error.png" alt="">
            <router-link
              to="/index.html"
            >
              <div class="redirect_button">
                <img src="img/404_button.png" alt="">
              </div>
            </router-link>
          </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'notFound',
  components: {
  },
  data() {
    return {
    }
  },
  created () {
    document.body.setAttribute('class','homebody');
  },
  mounted() {
  },
}
</script>