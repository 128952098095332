function loadJs(url) {
    return new Promise((resolve, reject) => {
        const script = document.createElement("script")
        script.src = url
        script.onload = resolve
        script.onerror = reject
        document.body.appendChild(script)
    });
}
async function asyncLoadJs(para) {
    await loadJs('https://man.vm5apis.com/dist/jquery/2.1.3/jquery.min.js').catch(err => { })
    await loadJs('https://man.vm5apis.com/dist/sk-vmfive.js/sk-vmfive.js').catch(err => { })
    await loadJs('https://man.vm5apis.com/dist/raf.js').catch(err => { })
    await loadJs('https://d2v4tz4zvhrua.cloudfront.net/production/dist/2020-04-20-1587354435084/common/TemplateBuilder.min.js').catch(err => { })
}
asyncLoadJs().then(() => {
    let template = new TemplateBuilder({
        cdn: 'https://d2v4tz4zvhrua.cloudfront.net/production/dist/2020-12-31-1609397877791',
        plugins: [
        ],
        adformats: [
          'BannerContainer'
        ],
        components: [
          'Attic', 'CloseButton'
        ],
        develop: false,
        Tracking: [
        function (self) {
          return {
            option: {
              el: '#vmfive-ad-unit-container-55667788',
              'request_id': '[request_id]',
              wantedTrackingList: [
                'impression', 'close', 'custom', 'view', 'video_percentage', 'video_progress', {'expand': 1}, {'collapse': 1}
              ],
              fbPixelId: '',
              wantedFbPixelList: [],
            },
            demoOption: {
              'text:3rd_party_track_url': '',
            }
          }
        }
      ],
      BaseAdUnit: [
        function(self) {
          return {
            adformatName: 'BannerContainer',
            refName : 'BannerContainer',
            option : {
              el: '#vmfive-ad-unit-container-55667788',
              'text:banner_top_offset': ``,
              'text:banner_bottom_offset': ``,
              'text:scale': ``,
              'text:expand_scale': ``,
              'text:max_width': ``,
              'text:embedded': ``,
              'text:height_ratio': ``,
              'text:background_color': '',
              'text:logo_horizontal_alignment': 'right',
              'text:logo_vertical_alignment': 'bottom',
              'text:logo_background_color': 'gray',
              'text:banner_ratio': '32:17',
              onbannerinit: [
                function(comp) {
                  let logoEl = comp.rootView.querySelector(`#vmfive-logo-${comp.config.request_id}`);
                  logoEl.style.right = '12px';
                  logoEl.style.bottom = '6px';
                }
              ],
              disableCta: true,
              enableClipPath: false,
              TrackingHelper: self.TrackingHelper,
            },
            demoOption: {
              'cta:default': 'https://adison.com.tw/landing/?utm_source=adison_ad_cta',
            }
          };
        },
      ],
      OnBaseAdUnitPrepared: [
        function (self) {
          return {
            componentName: 'Attic',
            refName: 'Attic',
            option: {
              el: self.refs['BannerContainer'].adContainerView,
              mirroring: true,
              TrackingHelper: self.TrackingHelper,
            },
            demoOption: {
              'text:cta_in_new_window': 'false',
              medias:
              '['+
              '{"mp4": "https://d2v4tz4zvhrua.cloudfront.net/manual_uploads/20201002/resource/attic_video.mp4", "coverimage": "https://d2v4tz4zvhrua.cloudfront.net/manual_uploads/20201002/resource/attic_cover.png", "ctaUrl": "https://adison.com.tw/landing/?utm_source=adison_ad_cta"},'+
              '{"image": "https://d2v4tz4zvhrua.cloudfront.net/manual_uploads/20201002/resource/attic_banner_01.png", "ctaUrl": "https://adison.com.tw/landing/?utm_source=adison_ad_cta"},'+
              '{"image": "https://d2v4tz4zvhrua.cloudfront.net/manual_uploads/20201002/resource/attic_banner_02.png", "ctaUrl": "https://adison.com.tw/landing/?utm_source=adison_ad_cta"},'+
              '{"image": "https://d2v4tz4zvhrua.cloudfront.net/manual_uploads/20201002/resource/attic_banner_03.png", "ctaUrl": "https://adison.com.tw/landing/?utm_source=adison_ad_cta"},'+
              '{}]',
              'cta_prefix_url': 'https://vaser-track.vm5apis.com/v2/track?requestId=&placementId=&campaignId=&audienceGroupId=&creativeId=&timezone=8&creativeFormat=richmedia&pixel=1&x=0&event=click',
              'cta:default': 'https://adison.com.tw/landing/?utm_source=adison_ad_cta',
              disableAutoPlay: false,
              autoPlayPeriod: 3000,
              onprepared: [
                function() {
                  self.refs['CloseButton'].init();
                }
              ],
            }
          };
        },
        function (self) {
          return {
            componentName: 'CloseButton',
            refName: 'CloseButton',
            option: {
              el: self.refs['BannerContainer'].adContainerView,
              disableAutoInit: true,
              TrackingHelper: self.TrackingHelper,
            },
            demoOption: {
              'text:show_close_mode': 'fadein',
              'text:close_button_horizontal_alignment': 'right',
              'text:close_out_container': '',
              'text:beautify_percent': '80',
            }
          }
        },
      ]
    });
});
