function loadJs(url) {
    return new Promise((resolve, reject) => {
        const script = document.createElement("script")
        script.src = url
        script.onload = resolve
        script.onerror = reject
        document.body.appendChild(script)
    });
}
async function asyncLoadJs(para) {
    // await loadJs('https://cdnjs.cloudflare.com/ajax/libs/jquery/2.1.3/jquery.min.js').catch(err => { })
    // await loadJs('https://man.vm5apis.com/dist/hammer.js/2.0.8/hammer.min.js').catch(err => { })
    // await loadJs('https://man.vm5apis.com/dist/jquery.hammerjs/2.0.0/jquery.hammer.js').catch(err => { })
    await loadJs('https://d2v4tz4zvhrua.cloudfront.net/production/dist/2021-07-19-1626682267011/common/TemplateBuilder.min.js').catch(err => { })
}
asyncLoadJs().then(() => {
    let template = new TemplateBuilder({
        cdn: 'https://d2v4tz4zvhrua.cloudfront.net/production/dist/2021-07-30-1627619983081',
        plugins: [],
        adformats: [
          'BannerContainerBackground', 'FSInterstitial'
        ],
        components: [
          'NewCloseButton', 'ExpandHelper', 'FSQuestion'
        ],
        develop: false,
        developRelativePath: '../../../..',
        Tracking: [
          function (self) {
            return {
              option: {
                el: '#vmfive-ad-unit-container-55667788',
                'request_id': '[request_id]',
                wantedTrackingList: [
                  'impression', 'close', 'custom', {
                    'expand': 1
                  }, {
                    'collapse': 1
                  }, {
                    'accept_invitation': 1
                  }
                ],
                fbPixelId: '748409338986541',
                wantedFbPixelList: [
                  'impression', 'close', 'custom', {
                    'expand': 1
                  }, {
                    'collapse': 1
                  }, {
                    'accept_invitation': 1
                  }
                ],
              },
              demoOption: {
                'text:3rd_party_track_url': '`{"party":"dv360","event":"expand","url":"http://www.google.com"}, ` +',
              }
            }
          }
        ],
        AdBox: [
          function (self) {
            return {
              refName: 'BaseAdBox',
              option: {
                boxStyle: 'fixed',
                plugins: []
              },
              demoOption: {
                'request_id': '55667788',
              }
            }
          },
          function (self) {
            return {
              refName: 'ExpandAdBox',
              option: {
                boxStyle: 'fixed',
                plugins: [
                  'https://man.vm5apis.com/dist/MixPlayer/1.4.0/MixPlayer.js'
                ]
              },
              demoOption: {
                'request_id': '55667788-expand',
              }
            }
          }
        ],
        BaseAdUnit: [
          function (self) {
            return {
              adformatName: 'BannerContainerBackground',
              refName: 'BannerContainerBackground',
              option: {
                el: self.refs['BaseAdBox'].practicalAdContainer,
                enableClipPath: false,
                disableCta: true,
                TrackingHelper: self.TrackingHelper,
                AdBox: self.refs['BaseAdBox'],
                'text:banner_ratio': '32:15',
                onctaclicked: [
                  function () {
                    self.refs['ExpandHelper'].expandedVisible('show');
                    self.refs['ExpandHelper'].originalVisible('hide');
                  }
                ]
              },
              demoOption: {
                'cta:default': 'https://www.google.com/search?q=default',
                'image:container_background': 'https://d2v4tz4zvhrua.cloudfront.net/manual_uploads/20210728/QA/QA_banner.png',
              }
            };
          },
          function (self) {
            return {
              adformatName: 'FSInterstitial',
              refName: 'FSInterstitial',
              option: {
                el: self.refs['ExpandAdBox'].practicalAdContainer,
                TrackingHelper: self.TrackingHelper,
                AdBox: self.refs['ExpandAdBox'],
                disableImpression: true
              },
              demoOption: {
                'image:background_image': ``,
                'image:foreground_image': ``,
                'video:video1:mp4': '',
                'image:cover': ``,

                'cta:default': `https://adison.com.tw/landing?utm_source=adison_ad_cta`,
                'text:logo_vertical_position': 'bottom',
                'text:logo_horizontal_position': 'left',
              }
            };
          }
        ],
        OnBaseAdUnitPrepared: [
          function (self) {
            return {
              componentName: 'FSQuestion',
              refName: 'FSQuestion',
              option: {
                el: self.refs['FSInterstitial'].adContainerView,
                disableCta: false,
                TrackingHelper: self.TrackingHelper,
                AdBox: self.refs['ExpandAdBox'],
                questions: '[' +
                  '{"option": "[text:option1]", "answer": "[text:answer1]", "ctaURL": "[cta:option1_cta]"},' +
                  '{"option": "[text:option2]", "answer": "[text:answer2]", "ctaURL": "[cta:option2_cta]"},' +
                  '{"option": "[text:option3]", "answer": "[text:answer3]", "ctaURL": "[cta:option3_cta]"},' +
                  '{"option": "[text:option4]", "answer": "[text:answer4]", "ctaURL": "[cta:option4_cta]"},' +
                  '{}]',
              },
              demoOption: {
                'cta:default': `https://adison.com.tw/landing?utm_source=adison_ad_cta`,
                'text:go_to_delay': '1500',
                'text:questionText': '甜甜圈<br>你最喜歡什麼口味？',
                questions: '[' +
                  '{"option": "原味糖霜", "answer": "經典最對味", "ctaURL": "https://adison.com.tw/landing?utm_source=adison_ad_cta"},' +
                  '{"option": "脆皮巧克力", "answer": "濃郁浪漫味", "ctaURL": "https://adison.com.tw/landing?utm_source=adison_ad_cta"},' +
                  '{"option": "日式抹茶", "answer": "苦甜大人味", "ctaURL": "https://adison.com.tw/landing?utm_source=adison_ad_cta"},' +
                  '{"option": "草莓糖霜", "answer": "甜蜜幸福味", "ctaURL": "https://adison.com.tw/landing?utm_source=adison_ad_cta"},' +
                  '{"option": "海鹽堅果", "answer": "滿足健康味", "ctaURL": "https://adison.com.tw/landing?utm_source=adison_ad_cta"},' +
                  '{}]',
                'image:question_background': 'https://d2v4tz4zvhrua.cloudfront.net/manual_uploads/20210728/QA/QA_expand.png',
                'text:question_text_color': '',
                'text:option_text_color': '',
                'text:option_bg_color': '',
              }
            }
          },
          function (self) {
            return {
              componentName: 'ExpandHelper',
              refName: 'ExpandHelper',
              option: {
                original: self.refs['BaseAdBox'].boxEl,
                expanded: self.refs['ExpandAdBox'].boxEl,
                expandedId: self.refs['ExpandAdBox'].config['request_id'],
                showExpandButton: true,
                TrackingHelper: self.TrackingHelper,
                onprepared: [
                  function (comp) {
                    comp.expandedVisible('hide');
                    comp.originalVisible('show');
                  }
                ],
                onShow: [
                  function (comp) {
                    self.refs['ExpandAdBox'].disableScroll();
                    self.refs['ExpandAdBox'].height = window.innerHeight + 'px';
                    self.refs['ExpandNewCloseButton'].showButton();
                    self.refs['NewCloseButton'].hideButton();
                  }
                ]
              },
              demoOption: {}
            }
          },
          function (self) {
            return {
              componentName: 'NewCloseButton',
              refName: 'NewCloseButton',
              option: {
                el: self.refs['BaseAdBox'].boxWrapper,
                AdBox: self.refs['BaseAdBox'],
                closeTarget: [self.refs['BaseAdBox'].boxWrapper, self.refs['ExpandAdBox'].boxWrapper],
                TrackingHelper: self.TrackingHelper,
                unique_name: 'default',
              },
              demoOption: {
                'text:show_close_mode': 'fadein',
                'text:vertical_align_button_percentage': '0',
                'text:horizontal_align_button_percentage': '100',
                'text:close_out_container': 'true',
              }
            }
          },
          function (self) {
            return {
              componentName: 'NewCloseButton',
              refName: 'ExpandNewCloseButton',
              option: {
                el: self.refs['FSInterstitial'].adContainerView,
                'request_id': '[request_id]-expand',
                TrackingHelper: self.TrackingHelper,
                onExpandHelperClose: [
                  function (comp) {
                    self.refs['ExpandAdBox'].enableScroll();
                    self.refs['ExpandHelper'].expandedVisible('hide');
                    self.refs['ExpandHelper'].originalVisible('show');
                    self.refs['NewCloseButton'].showButton();
                  }
                ],
                autoShowButton: false,
                'text:close_out_container': 'false',

                'cta:custom_close': `[cta:custom_close_expand]`,
                'text:vertical_align_button_percentage': '[text:vertical_align_button_percentage_expand]',
                'text:horizontal_align_button_percentage': '[text:horizontal_align_button_percentage_expand]',
                'text:show_close_mode': `[text:show_close_mode_expand]`,
                'text:close_style': `[text:close_style_expand]`,
                'text:close_mode_second': '[text:close_mode_second_expand]',
                'text:beautify_percent': '[text:beautify_percent_expand]',
              },
              demoOption: {
                'request_id': '55667788-expand',
                'text:show_close_mode': 'fadein',
                'text:vertical_align_button_percentage': '0',
                'text:horizontal_align_button_percentage': '100',
              }
            }
          }
        ]
      });
})