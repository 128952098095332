<template>
  <div class="home" :class="{ conditionalClass:true }">
    <transition name="t1" v-if="!categoryBlockIsOn">
      <div class="main">
        <div class="logo">
          <img src="img/logo.png" alt="adison powered by vmfive">
        </div>
        <div class="filter">
          <ul>
            <router-link v-for="(item) in categoryList"
                        :key="item.index"
                        :to="{ query: { q: item.key }}"
                        class="filter_li"
                        tag="li">
              <img :src="item.src" :alt="item.name">
              <h3>{{item.name}}</h3>
              <p>{{item.enName}}</p>
            </router-link>
          </ul>
        </div>
      </div>
    </transition>
    <transition name="t2" v-else>
      <CategoryList :categoryKey="openCategoryCurrent">
      </CategoryList>
    </transition>
    <div class="background-wrap" :style=" 'background: url(img/background.png);background-size: 100%;' "></div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import CategoryList from '../components/categoryList'

export default {
  name: 'home',
  components: {
    CategoryList
  },
  mounted() {
  },
  data() {
    return {
      categoryList: [
        // {name:'精選廣告', enName:'Featured Ads', key: 'Featured', src:'img/featured.png'},
        {name:'動態資訊', enName:'Dynamic Info Ads', key:'Dynamic', src:'img/dynamic_info.png'},
        {name:'文中廣告', enName:'In-Read Ads', key:'InRead', src:'img/inread.png'},
        {name:'置底廣告', enName:'Slider Ads', key: 'Slider', src:'img/dynamic.png'},
        {name:'蓋版廣告', enName:'Interstitial Ads', key: 'Interstitial', src:'img/interactive.png'},
        {name:'精選案例', enName:'Showcase', key: 'Showcase', src:'img/aac.png'},
        {name:'PC 廣告', enName:'Pc Ads', key: 'Pc', src:'img/pc.png'},
      ],
      categoryBlockIsOn: false,
      openCategoryCurrent: {}
    }
  },
  created () {
    document.body.setAttribute('class','homebody');
    if( this.$route.query.q != undefined ) {
      this.categoryBlockIsOn = true;
      // 找出當前 route 在 categoryList 的 q
      this.openCategoryCurrent = this.categoryList.filter(obj => { return obj.key === this.$route.query.q; })
    }
  },
  watch: {
    '$route' (to, from) {
      if( from.query.q === undefined ) {
        this.categoryBlockIsOn = true;
        // 找出當前 route 在 categoryList 的 q
        this.openCategoryCurrent = this.categoryList.filter(obj => { return obj.key === this.$route.query.q; })
      } else {
        this.categoryBlockIsOn = false;
      }
    }
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">

.t1-enter-active, .t1-leave-active {
  transition: all 0.2s ease-in-out;
}
.t1-leave-active {
  position: absolute;
  top: 0;
}
// manin 回
.t1-enter {
  transform: translateX(-100%);
  opacity: 0;
}
.t1-enter-to {
  transform: translateX(0%);
  opacity: 1;
}

list 回
.t1-leave {
  transform: translateX(0%);
  opacity: 1;
}
.t1-leave-to {
  transform: translateX(100%);
  opacity: 0;
}

.t2-enter-active, .t2-leave-active {
  transition: all 0.2s ease-in-out;
}
.t2-enter-active {
  position: absolute;
  top: 0;
}
// list 去
.t2-enter {
  transform: translateX(100%);
  opacity: 1;
}
.t2-enter-to {
  transform: translateX(0%);
  opacity: 1;
}
// main 去
.t2-leave {
  transform: translateX(0%);
  opacity: 0;
}
.t2-leave-to {
  transform: translateX(-100%);
  opacity: 0;
}
</style>
